import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/self.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import Title from '../../components/Title';
import '../../App.css';

// 板块二数据

let pageTimer = '' //停留时间

const iconBanner = [
    { icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_banner1.png', text: '一站式搭建'},
    { icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_banner2.png', text: '专人售后'},
    { icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_banner3.png', text: '总部运营指导'}
]

const plate3Data = [
    { 
        icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3_1.png',
        iconActive: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3_ac1.png',
        image: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3Image1.png',
        text: '来云台骑手端'
    },
    { 
        icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3_2.png',
        iconActive: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3_ac2.png',
        image: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3Image2.png',
        text: '来云台商家端'
    },
    { 
        icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3_2.png',
        iconActive: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3_ac2.png',
        image: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/self_plate3Image3.png',
        text: '来云台调度后台'
    }
]
const plate4Data = [
    { 
        text: 'f**',
        phone: '183******90'
    },
    { 
        text: 'c*',
        phone: '153******89'
    },
    { 
        text: 'g**',
        phone: '131******38'
    },
    { 
        text: 'd**',
        phone: '172******39'
    },
    { 
        text: 'h**',
        phone: '136******00'
    },
    { 
        text: 'k**',
        phone: '159******52'
    },
    { 
        text: 's*',
        phone: '174******15'
    },
    { 
        text: 'x**',
        phone: '133******50'
    },
    { 
        text: 'd*',
        phone: '152******89'
    },
    { 
        text: 'q**',
        phone: '136******00'
    },
    { 
        text: 'x*',
        phone: '155******13'
    },
    { 
        text: 'l*',
        phone: '134******85'
    },
    { 
        text: 't*',
        phone: '136******74'
    },
    { 
        text: 'd*',
        phone: '152******69'
    },
    { 
        text: 'q**',
        phone: '136******89'
    },
    { 
        text: 'c**',
        phone: '159******52'
    },
    { 
        text: 'l*',
        phone: '174******15'
    },
    { 
        text: 'f*',
        phone: '133******50'
    },
    { 
        text: 'd*',
        phone: '152******79'
    },
    { 
        text: 'y**',
        phone: '136******33'
    },
    { 
        text: 'c*',
        phone: '159******52'
    },
    { 
        text: 'l*',
        phone: '174******15'
    }
]

class Self extends React.Component {
    state = {
        phone: '',
        baneerIndex: 0,
        plate3Index: 0
    }
    componentWillMount() {
        // 获取进入页面的时间
        if (pageTimer === '') {
            pageTimer = parseInt(new Date().getTime() / 1000);
            console.log(pageTimer, 'pageTimer')
        }
        this.props.onVisitClick({
            type: 1,
            domain: 'www.qolai.com',
            page: 'self'
        }, 0)
    }

    componentWillUnmount() {
        // 离开时间减去进入时间
        const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
        console.log(tempTimer, 'tempTimer')
        // 清空时间戳
        pageTimer = '';
        if (tempTimer > 0) {
            this.props.onVisitClick({
                type: 2,
                domain: 'www.qolai.com',
                page: 'self',
                time: tempTimer
            }, 0)
        }
    }
    render() {
        return (
            <div className="w-100">
                <Helmet>
                    <title>来云台自配送系统-外卖配送软件|商家自配送app （com网站 ）</title>
                    <meta name="Description" content="来云台自配送系统是针对美团、饿了么、抖音等三方外卖平台商家，不选用平台提供的“专送”，而是选择第三方配送平台服务来解决自身配送问题；对于外卖平台商家来说，降低配送成本、提升了订单的准时性、骑手配送过程中的可视化管理。" />
                    <meta name="Keywords" content="自配送系统,外卖自配送软件,商家自配送app" />
                </Helmet>
                <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
                    <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
                        <div style={{ marginLeft: '318px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
                            <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>自配送系统——<span style={{ color: '#F6BB07' }}>极速、智能、安全新体验</span></div>
                            <div className={styles.iconBanner}>
                                {
                                    iconBanner.map((row,col) => (
                                        <div className={styles.iconBannerItem} key={col} >
                                            <img alt='' src={row.icon} />
                                            <span>{row.text}</span>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='Carousel-button d-flex animated fadeInRight' style={{marginTop: '70px'}}>
                                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({ phone: e.target.value }) }} placeholder='请输入您的手机号' />
                                <div onClick={() => { 
                                    this.props.onVisitClick({
                                        type: 3,
                                        domain: 'www.qolai.com',
                                        page: 'self',
                                        button: 'free_use'
                                    },0);
                                    this.props.submit(this.state.phone) 
                                }} className='Carousel-btn'>申请免费体验</div>
                            </div>
                        </div>
                    </div>
                </Carousel>
                {/**来云台自配送系统及优势  板块2**/}
                <div style={{ padding: '78px 0 81px', background: '#F7F9FC' }}>
                    <Title title="来云台自配送系统及优势"
                        text="ADVANTAGE"
                        width={710}
                        height={66}
                        marginBottom={43} />
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src={require('../../asset/self/plate2Img1.png')} style={{ width: '129px', height: '146px', display: 'block'}} alt=''></img>
                            <div className={styles.plate2Text}>
                                来云台自配送系统是针对<span className={styles.fC6}>美团、饿了么、抖音</span>等第三方平台订单，不选用平台提供的“专送”，而是选择<span className={styles.fC6}>第三方配送平台服务</span>
                                来解决自身配送问题；对于外卖平台商家来说，<span className={styles.fC6}>降低配送成本、提升了订单的准时性、骑手配送过程中的可视化管理。</span>
                            </div>
                        </div>
                    {/* <img src={require('../../asset/self/plate2Img2.png')} style={{ width: '1280px', height: '285px', display: 'block', margin: '0 auto' }} alt=''></img> */}
                    {/* <img src={require('../../asset/self/plate2Img3.png')} style={{ width: '1280px', height: '365px', display: 'block', margin: '60px auto 0' }} alt=''></img> */}
                </div>
                {/**做自配送平台订单来源  板块3**/}
                <div style={{ padding: '78px 0px', background: '#FFFFFF' }}>
                    <Title title="做自配送平台订单来源"
                        text="ORDER SOURCE"
                        width={710}
                        height={66}
                        marginBottom={55} />
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src={require('../../asset/self/plate3Img1.png')} style={{ width: '748px', height: '490px', display: 'block'}} alt=''></img>
                            <div className={styles.plate3Text}>
                                <div className={styles.plate3TextItem}>
                                    <div className={styles.plate3TextTip}></div>
                                    <div className={styles.font20}>一键自动发单</div>
                                    <div className={styles.font16}>美团、饿了么、抖音、京东到家自配送商家</div>
                                </div>
                                <div className={styles.plate3TextItem}>
                                    <div className={styles.plate3TextTip}></div>
                                    <div>
                                        支持导入<span className={styles.fC6}>美团外卖、饿了么、抖音、京东到家</span>等第三方平台订单,通过订单分发平台（<span className={styles.fC6}>青云、麦芽田、餐道</span>
                                        ）推送到自营平台的骑手端,由<span className={styles.fC6}>自营平台骑手</span>进行抢单/派单并提供跑腿服务
                                    </div>
                                </div>
                                <div className={styles.plate3TextItem}>
                                    <div className={styles.plate3TextTip}></div>
                                    <div>
                                        针对商家，<span className={styles.fC6}>商户赠送满减券、折扣券，充值赠送现金</span>等营销功能快速吸引商家入驻。
                                    </div>
                                    <div>
                                        针对骑手，系统拥有完善的<span className={styles.fC6}>骑手保险、骑手排班、骑手考核制度、骑手智能派/抢单、骑手路径智能规划等</span>
                                        功能体系，智能化管理骑手。
                                    </div>
                                    <div>
                                        来云台自配送平台拥有<span className={styles.fC6}>完善的自动分账功能</span>，保障商家、骑手、平台的资金安全。
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                {/**产品介绍  板块3**/}
                <div style={{ padding: '78px 0 81px', background: '#F7F9FC' }}>
                    <Title title="产品介绍"
                        text="INTRODUCTION"
                        width={710}
                        height={66}
                        marginBottom={43} />
                        <div className={styles.plate3}>
                            <div>
                                {
                                    plate3Data.map((item,index) => {
                                        return (
                                            <div key={index} className={this.state.plate3Index == index ? `${styles.plate3LeftActive} ${styles.plate3Left}` : styles.plate3Left} onMouseOver={() => {this.setState({plate3Index: index})}}>
                                                <img alt='' src={this.state.plate3Index == index ? item.iconActive : item.icon} />
                                                <span>{item.text}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-center' style={{flex: 1}}>
                                <img src={plate3Data[this.state.plate3Index].image} style={{ width: '800px', height: '520px', display: 'block' }} alt=''></img>
                            </div>
                        </div>
                </div>
                {/**搭建流程  板块4**/}
                <div style={{ padding: '78px 0 0px', background: '#FFFFFF', marginBottom: '-20px'}}>
                    <Title title="搭建流程"
                        text="BUILD PROCESS"
                        width={710}
                        height={66}
                        marginBottom={40} />
                    <img src={require('../../asset/self/plate4Img1.png')} style={{ width: '1280px', height: '142px', display: 'block', margin: '0px auto 40px' }} alt=''></img>
                    {/* <div className={styles.plate4}>
                        <div className={styles.plate4Title}>自配送平台搭建申请</div>
                        <div className={styles.plate4Btn} onClick={()=> {window.open('https://www.wjx.top/vm/h68KNkL.aspx#')}}>立即申请</div>
                        <div className="carousel-box" style={{margin: '30px auto 0'}}>
                            <div className="scrolList-box">
                                {plate4Data.map((item,index)=>{
                                    return (
                                        <div className="scrolList" style={{paddingTop:index === 0 ? '10px' : ''}}>
                                            <div className="d-flex align-items-center">
                                                <img alt="" src={require('../../asset/self/record.png')} style={{width:'16px',height:'16px',marginRight:'10px'}}/>
                                                <span>{item.text}</span>
                                            </div>
                                            <span class="item2">{item.phone}</span>
                                            <span class="item3" className={styles.fC6}>申请成功</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Self;