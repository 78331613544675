import React, { useState, useEffect } from 'react';
import { Button, message, Input } from 'antd';
import { Helmet } from 'react-helmet';
// apis
import { userSubmit } from '../../apis/About';

let pageTimer = '' //停留时间

function About(props) {
  // 百度地图初始化
  useEffect(() => {
    if (window.BMap) {
      const BMap = window.BMap;
      const map = new BMap.Map("yili-map");
      const point = new BMap.Point(120.21,33.37);
      const marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      map.centerAndZoom(point, 16);
      var opts = {
        width: 200, // 信息窗口宽度
        height: 100, // 信息窗口高度
        title: "盐城中联数科技术有限公司", // 信息窗口标题
        enableMessage: true, //设置允许信息窗发送短息
        message: "亲耐滴，戳下面的链接看下地址喔~"
      }
      const infoWindow = new BMap.InfoWindow("地址：盐城经济技术开发区新都东路82号8幢B1楼507室", opts); // 创建信息窗口对象
      marker.addEventListener("click", function() {
        map.openInfoWindow(infoWindow, point); //开启信息窗口
      })
    }
    // 获取进入页面的时间
    if (pageTimer === '') {
      pageTimer = parseInt(new Date().getTime() / 1000);
      console.log(pageTimer, 'pageTimer')
    }
    props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'about'
    }, 0)

    // 这个返回的函数，会在该组件卸载时来执行
    // 因此，可以去执行一些清理操作，比如，解绑 window 的事件、清理定时器 等
    return () => {
      // 离开时间减去进入时间
      const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
      console.log(tempTimer, 'tempTimer')
      // 清空时间戳
      pageTimer = '';
      if (tempTimer > 0) {
        props.onVisitClick({
          type: 2,
          domain: 'www.qolai.com',
          page: 'about',
          time: tempTimer
        }, 0)
      }
    }
  }, [])
  // 姓名
  const [name, setName] = useState('');
  // 电话
  const [phone, setPhone] = useState('');
  // 邮箱
  const [email, setEmail] = useState('');
  // 建议
  const [proposal, setProposal] = useState('');

  const mysubmit = async () => {
    const phoneExp = /^1[3456789]\d{9}$/;
    const emailExp = /^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$/;

    if (name === '') {
      message.error('请填写姓名！')
      return
    }

    if (phone === '' || !phoneExp.test(phone)) {
      message.error('请填写正确的电话号码！')
      return
    }

    if (email === '' || !emailExp.test(email)) {
      message.error('请填写正确的邮箱！')
      return
    }

    if (proposal === '') {
      message.error('请填写建议！')
      return
    }

    const result = await userSubmit(name, email, phone, proposal);
    console.log(result)
    if (result.code === 0){
      message.success('提交成功！')
    }else {
      message.success(result.message)
    }
  }

  return (
    <div >
      <Helmet>
          <title>来云台-同城配送行业软件|短距离物流配送管理系统|软件代理加盟</title>
          <meta name="Description" content="盐城中联数科技术公司专注研发来云台短距物流配送管理系统，我们坚持以市场需求为导向，用户体验的简便性为核心在产品功能开发、技术升级、消费者体验等方面持续进行研发投入，用实际需求的互联网思想帮助配送行业创业者提升竞争力" />
          <meta name="Keywords" content="短距离物流配送管理系统,软件开发,同城配送行业软件,软件代理加盟,同城跑腿软件" />
      </Helmet>
      {/**banner**/}
      <div className="about-banner d-flex justify-content-center align-items-center ">
        <div style={{width:'1290px'}}>
          <div className="animated fadeInLeft" style={{marginLeft: '27px',marginBottom:'120px'}}>
            <h1 style={{marginBottom:'19\0px'}} className="about-title">盐城中联数科技术有限公司，让科技更有价值</h1>
            <span className="about-dec">截止现在</span>
          </div>
          <div className="d-flex animated fadeInRight">
            <div className="d-flex flex-column" style={{padding: '0px 30px'}}>
              <span className="about-right-title text-center">服务团队</span>
              <span className="about-right-num text-center">4000+</span>
              <span className="about-right-dec text-center">Enterprise</span>
            </div>
            <div className="d-flex flex-column" style={{padding: '0px 30px',borderRight: '1px solid #ffffff', borderLeft: '1px solid #ffffff'}}>
              <span className="about-right-title text-center">服务用户</span>
              <span className="about-right-num text-center">1000000+</span>
              <span className="about-right-dec text-center">Client</span>
            </div>
            <div className="d-flex flex-column" style={{padding: '0px 30px'}}>
              <span className="about-right-title text-center">入驻商户</span>
              <span className="about-right-num text-center">30000+</span>
              <span className="about-right-dec text-center">traffic</span>
            </div>
          </div>
        </div>
      </div>
      {/**板块二**/}
      <div className="about-plate-2">
        <div className="d-flex justify-content-center align-items-center">
          <div className="" style={{marginRight: '128px'}}>
            <h4 className="about-plate-2-title">盐城中联数科技术有限公司</h4> <span className = "d-block"
            style = { { width: '50px', height: '2px', background: '#F5AD01' } } > </span>
            <p className="about-plate-2-text-main">
              <span>盐城中联数科技术有限公司，秉持“以壹为始，立壹之上”的企业发展理念，从壹出发，不忘初心，潜心打造产品、打造值得用户信赖的产品，提供体系完善的品质服务，连续多年稳健运营，久经市场重重考验，业务丰富，为全行业企业信息化做持久贡献，成熟的运营模式让企业拥有全球多个城市的合作商，合同到期合伙人续约率高，深受合作商认同。</span>
              <br />
              <span className="d-inline-block" style={{marginTop: '20px'}}>盐城中联数科技术有限公司，创立于天府蓉城“高端产业新城”——高新区，公司专注于互联网技术研发，是一家集软件定制、开发、服务于一体的个性化软件系统研发企业。</span>
              <br />
              <span className="d-inline-block" style={{marginTop: '20px'}}>目前，盐城中联数科技术有限公司旗下有包括壹脉销客AI智能名片、来云台跑腿系统、来云台外卖系统、来云台校园跑腿系统、来云台校园外卖系统等多个公司自主品牌、自主产权、自主研发设计的软件系统，公司始终着眼于用户的需求，以帮助企业走上“互联网+”之路、实现企业运营管理的规范化、科学化、现代化为已任，把开发出符合客户需求的软件系统当做自己的使命，研发出一系列软件，满足不同企业的需求。+”之路、实现企业运营管理的规范化、科学化、现代化为已任，把开发出符合客户需求的软件系统当做自己的使命，研发出一系列软件，满足不同企业的需求。</span>
              <br />
              <span className="d-inline-block" style={{marginTop: '20px'}}> 公司集开发、运营、销售、服务于一体，拥有一支开发经验丰富的精英技术团队，服务超过4000+个合作商，在研发运营公司自营成熟产品，提供给客户系统软件技术服务的同时，也为客户提供移动互联网产品定制开发服务，包括微信公众号、小程序、APP等定制开发。</span>
              <br />
              <span className="d-inline-block" style={{marginTop: '20px'}}>丰富的开发经验，在技术上精益求精，为传统企业客户带来变量，为互联网创业者实现商业梦想。</span>
            </p>
          </div>
          {/**右侧图片**/}
          <div style={{marginTop: 122}}>  
            <img alt="img" src={require(`../../asset/about/aboutyili.png`)} style={{height: '300px',width: '533px'}}/>
          </div>
        </div>
      </div>
      <div style={{marginTop: '100px', paddingTop: '100px'}} className="d-flex justify-content-center">
        <div style={{marginRight: '144px'}}>
          <div id="yili-map">
          </div>
        </div>
        <div className="about-form">
          <h4 className="m-0" style={{color: '#333333', fontSize: '36px', lineHeight: '58px'}}>联系我们</h4>
          <span className="d-inline-block" style={{width: '50px', height: '2px', background: '#F5AD01'}}></span>
          <div style={{marginTop: '50px'}} className="d-flex align-items-center">
            <div className="flex-grow-1">
              <label style={{fontSize: '16px', color: '#35373D', marginBottom: '15px'}} className="d-block">姓名</label>
              <Input maxLength="6" className="form-input w-100" placeholder="请输入您的姓名" value={name} allowClear onChange={e => {setName(e.currentTarget.value)}}/>
            </div>
            <div className="flex-grow-1" style={{paddingLeft: '17px'}}>
              <label style={{fontSize: '16px', color: '#35373D', marginBottom: '15px'}} className="d-block">电话</label>
              <Input maxLength="11" className="form-input w-100" placeholder="请输入您的电话" value={phone} onChange={e => {setPhone(e.currentTarget.value)}} allowClear/>
            </div>
          </div>
          <div style={{marginTop: '30px'}} className="d-flex align-items-center">
            <div className="flex-grow-1">
              <label style={{fontSize: '16px', color: '#35373D', marginBottom: '15px'}} className="d-block">邮箱</label>
              <Input className="form-input w-100" placeholder="请输入您的邮箱" value={email} onChange={e => {setEmail(e.currentTarget.value)}} allowClear/>
            </div>
          </div>
          <div style={{marginTop: '30px'}} className="d-flex align-items-center">
            <div className="flex-grow-1">
              <label style={{fontSize: '16px', color: '#35373D', marginBottom: '15px'}} className="d-block">需求或建议</label>
              <Input.TextArea style={{height: 124}} className="form-input w-100" value={proposal} onChange={e => {setProposal(e.currentTarget.value)}} placeholder="请输入您的需求或建议"/>
            </div>
          </div>
          <Button onClick={() => {
            props.onVisitClick({
              type: 3,
              domain: 'www.qolai.com',
              page: 'sendMessage',
              button: '发送信息'
            },0);
            mysubmit()
          }} style={{marginTop: '30px'}} className="form-btn">发送信息</Button>
        </div>
      </div>
      {/**三张卡片**/}
      <div style={{marginTop: 124, paddingBottom: 124}} className="d-flex justify-content-center align-items-center">
        <div className="about-card d-flex justify-content-center align-items-center">
          <img alt="img" src={require(`../../asset/about/fa-phone.png`)} style={{width: 44,height: 44}}/>
          <div style={{marginLeft: 30}}>
            <span className="d-block">028-65731131</span>
            <span>17721872191   18081916514</span>
          </div>
        </div>
        <div className="about-card d-flex justify-content-center align-items-center" style={{margin: '0 20px'}}>
          <img alt="img" src={require(`../../asset/about/fa-envelope.png`)} style={{width: 44,height: 44}}/>
          <div style={{marginLeft: 30}}>
            <span className="d-block">邮箱地址</span>
            <span>yiliit@163.com</span>
          </div>
        </div>
        <div className="about-card d-flex justify-content-center align-items-center">
          <img alt="img" src={require(`../../asset/about/fa-map-marker.png`)} style={{width: 44,height: 44}}/>
          <div style={{marginLeft: 30}}>
            <span className="d-block">盐城经济技术开发区新都东路82号8幢B1楼507室</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;