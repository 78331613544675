import React from 'react'
import { Helmet } from 'react-helmet'
import CarouselIndex from '../../components/Carousel'
import Title from '../../components/Title'
import '../../css/Service.scss'


const Carousel_text = [
  {
    carouselImage: 'service/img_service_banner',
    text: [
      { text: '来云台', colorText: '' },
      { text: '，全方位满足各阶段用户所需', colorText: '标准化服务体系' }
    ],
    iconscenes: [
      { icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner10.png', text: 'SAAS租用', gray: true, width: '138px' },
      { icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner11.png', text: 'OEM品牌', gray: true, width: '138px' },
      { icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner12.png', text: '特殊功能定制', gray: true, width: '138px' },
      { icon: 'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner13.png', text: '独立部署', gray: true, width: '138px' }
    ],

  }
]

const moduleArr = [
  {
    icon: 'icon-service-modulesA1',
    title: 'SAAS租用',
    text: '基础版全套系统使用，搭建客户独立品牌的平台小程序，提供免费的技术维护服务、系统更新服务，以及专人售后服务'
  },
  {
    icon: 'icon-service-modulesA2',
    title: 'OEM品牌定制',
    text: '支持骑手和商家APP（Android+IOS）应用定制、团队后台、商户后台客户端定制，域名定制，短信定制，打造全套品牌化平台，享受升级单独更新'
  },
  {
    icon: 'icon-service-modulesA3',
    title: '特殊功能定制',
    text: '针对个别用户某些特殊功能需求，支持多对一定制服务，匹配产品人员、UI设计、前后端开发、测试全产品线小组服务'
  },
  {
    icon: 'icon-service-modulesA4',
    title: '独立部署',
    text: '支持单个用户平台服务独立部署，独立服务器，专人运维，保障信息数据独立且安全，需要随时导出'
  }
]
const scopeArr = [
  {
    img: 'icon-service-modulesB1',
    title: '塑造品牌形象',
    title2: '支持全套产品品牌定制，建立用户的品牌意识，树立形象的同时加深市场对品牌的认可度，彰显品牌实力'
  },
  {
    img: 'icon-service-modulesB2',
    title: '抢占市场先机',
    title2: '无需高额的技术开发成本，更不需要占用太长的开发周期，现成的、完善的O2O平台系统，快速抢占市场'
  },
  {
    img: 'icon-service-modulesB3',
    title: '加盟提升收益',
    title2: '除了自己本地运营，还支持多城市、多站点发展代理加盟商，多区域同步运营，收取站点佣金，提升收益'
  }
]
const flowArr = [
  {
    icon: 'bg-service-modulesD1',
    title: '沟通需求',
  },
  {
    icon: 'bg-service-modulesD2',
    title: '评估开发周期与人员配置',
  },
  {
    icon: 'bg-service-modulesD3',
    title: '签约打款',
  },
  {
    icon: 'bg-service-modulesD4',
    title: '设计功能产品原型',
  },
  {
    icon: 'bg-service-modulesD5',
    title: 'UI设计出图',
  },
  {
    icon: 'bg-service-modulesD6',
    title: '技术人员开发',
  },
  {
    icon: 'bg-service-modulesD7',
    title: '功能测试',
  },
  {
    icon: 'bg-service-modulesD8',
    title: '上线交付',
  },
]

let pageTimer = '' //停留时间

class Service extends React.Component {
  state = {

  };
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    if (window.location.hash) {
      return;
    }
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  componentWillMount() {
    let id = '#' + this.getQueryVariable('id')
    if (id !== "#undefined") {
      console.log(window.location.href)
      window.location.href = id
    }
    // 获取进入页面的时间
    if (pageTimer === '') {
      pageTimer = parseInt(new Date().getTime() / 1000);
      console.log(pageTimer, 'pageTimer')
    }
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'serviceid'
    }, 0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer, 'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.com',
        page: 'serviceid',
        time: tempTimer
      }, 0)
    }
  }


  shouldComponentUpdate(newValue, Oldvalue) {
    let id = '#' + this.getQueryVariable('id')
    if (id !== "#undefined") {
      console.log(window.location.href, id)
      window.location.href = id
    }
  }


  render() {
    return (
      <div>
        <Helmet>
          <title>来云台-SaaS系统租用|OEM品牌|特殊功能定制|独立部署</title>
          <meta name="Description" content="来云台针对前期创业、中期进阶、后期拓展所有阶段都提供有不同的价格、品牌化的针对性服务，让你拥有匹配你发展阶段的服务，拥有独立、自主、品牌发展空间更大，多元化服务类型全面满足用户。" />
          <meta name="Keywords" content="SAAS租用,OEM品牌,特殊功能定制,独立部署" />
        </Helmet>
        {/**顶部banner**/}
        <CarouselIndex data={Carousel_text}  getIPs={this.props.getIPs} />

        {/* 为什么选择来云台外卖系统 */}
        <div style={{ marginTop: '80px' }} id="1">
          <Title title="多元化服务类型全面满足用户"
            text="Diversified services"
            dec="针对前期创业、中期进阶、后期拓展所有阶段都提供有不同的价格、品牌化的针对性服务，让你拥有匹配你发展阶段的服务，拥有独立、自主、品牌发展空间更大"
            width={710}
            height={66} />
          <div className="d-flex justify-content-center">
            <div className="detaile-module5-box" style={{ width: '1440px', height: '338px', flexWrap: 'wrap' }}>
              {moduleArr.map((item, key) => {
                return (
                  <div key={key} className="detaile-module5-box-item" style={{ width: '338px', height: '296px' }}>
                    <img
                      alt=''
                      style={{
                        width: '80px',
                        height: '80px',
                      }}
                      src={require(`../../asset/service/${item.icon}.png`)} />
                    <div
                      style={{
                        fontSize: '24px',
                        color: '#333333',
                        marginBottom: '19px',
                        fontWeight: 'bold'
                      }}
                    >
                      {item.title}
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#707070',
                        fontWeight: '600',
                        textAlign: 'center'
                      }}
                    >
                      {item.text}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {/**来云台跑腿配送系统 - 价值亮点**/}
        <div>
          <Title title="OEM独立品牌，有哪些优势？"
            text="advantage"
            width={710}
            height={66} />
          <div className="d-flex justify-content-center">
            <div className="d-flex flex_column" style={{ width: "1440px" }}>
              <div className="service-scope-top">
                {scopeArr.map((item, index) => {
                  return (
                    <div key={index} className='box width460' style={{ backgroundImage: `url(${require(`../../asset/service/${item.img}.png`)})`, backgroundSize: '100% 100%', backgroundRepeat: ' no-repeat' }}>
                      <div className="scope-content">
                        <div className="scope-content-title">
                          {item.title}
                        </div>
                        <div className="scope-content-bottom">{item.title2}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* 为什么选择来云台外卖系统 */}
        <div style={{ marginTop: '80px' }} id="3">
          <Title title="来云台提供哪些OEM品牌定制服务？"
            text="customized service"
            width={710}
            height={66} />
          <div className="d-flex flex_column align-cen" style={{ height: '600px', backgroundImage: `url(${require('../../asset/service/bg-service-modulesC.png')})`, backgroundSize: '100% 100%', backgroundRepeat: ' no-repeat', paddingTop: '16px' }}>
            <div className="service-modulesC-text">
              √ 骑手APP（Android+IOS）应用定制，包含名称、logo
            </div>
            <div className="service-modulesC-text">
              √ 商户APP（Android+IOS）应用定制，包含名称、logo
            </div>
            <div className="service-modulesC-text">
              √ 商户PC后台定制，支持OEM后logo和头像自定义上传
            </div>
            <div className="service-modulesC-text">
              √ 商户后台域名定制
            </div>
            <div className="service-modulesC-text">
              √ 团队调度PC后台定制，支持logo和头像自定义上传
            </div>
            <div className="service-modulesC-text">
              √ 团队后台域名定制
            </div>
            <div className="service-modulesC-text">
              √ 短信OEM，定制后平台所有短信均以团队名称发送
            </div>
          </div>
        </div>

        {/* 为什么选择来云台外卖系统 */}
        <div style={{ marginTop: '80px' }} id="4">
          <Title title="特殊功能定制服务流程？"
            text="service process"
            width={710}
            height={66} />
          <div className="d-flex jscen" style={{ marginBottom: '100px' }}>
            {
              flowArr.map((item, index) => {
                return (
                  <div key={index} className='service-flow-box' style={{ color: (index + 1) % 2 == 1 ? '#333333' : '', background: (index + 1) % 2 == 1 ? '' : '#333333' }}>
                    <img
                      alt=''
                      style={{
                        width: '40px',
                        height: '40px',
                        marginBottom: '16px'
                      }}
                      src={require(`../../asset/service/${item.icon}.png`)} />
                    <p>
                      {item.title}
                    </p>
                    <img alt='' className={index == 7 ? 'display-none service-flow-arrows' : 'service-flow-arrows'} src={require(`../../asset/service/${(index + 1) % 2 == 1 ? 'yellow-triangle' : 'black-triangle'}.png`)} />
                  </div>
                )
              })

            }
          </div>
        </div>
      </div>
    )
  }
}

export default Service;