import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/errand_delivery.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import Title from '../../components/Title';

const plate2 = {
  processList: ['用户/商家下单', '骑手A接单配送', '送达至中转站', '骑手B接单配送', '送达至配送地址'],
  iconArr: [
    {
      text: '服务场景自定义',
      icon: 'plate2Icon1'
    },
    {
      text: '开放API接口',
      icon: 'plate2Icon2'
    },
    {
      text: '扫码快速录单',
      icon: 'plate2Icon3'
    },
    {
      text: '收取件码',
      icon: 'plate2Icon4'
    },
    {
      text: '多城市站点加盟',
      icon: 'plate2Icon5'
    }
  ]
}

const blate6List = {
  id: 1,
  leftData: [
    {
      title: '用户/商户下单',
      icon: 'blate6List1'
    },
    {
      title: '智能调度系统',
      icon: 'blate6List2'
    },
    {
      title: '骑手接单',
      icon: 'blate6List3'
    },
    {
      title: '骑手配送',
      icon: 'blate6List5'
    },
    {
      title: '订单配送完成',
      icon: 'blate6List4'
    }
  ],
  data: [
    {
      image: 'blate6Icon1',
      arr: [
        {
          cont: '下单方式多元，包括了微信小程序/H5、商家APP，第三方渠道订单。',
          text: '多元下单'
        },
        {
          cont: '支持在线支付、余额支付、银行卡支付、事后结算、好友代付等。',
          text: '支付方式'
        },
        {
          cont: '下单后用户/商户可以实时定位、追踪骑手位置。',
          text: '订单追踪'
        }
      ]
    },
    {
      image: 'blate6Icon2',
      arr: [
        {
          cont: '根据系统设置的派单规则，结合大数据技术智能派单给骑手，合理分配运力。',
          text: '条件指派'
        },
        {
          cont: '根据设定的调度配送规则，强制给最优骑手派单，无需担心无人抢单接单。',
          text: '强制派单'
        },
        {
          cont: '根据自定义设置的回程订单规则，自动优先指派回程单给骑手。',
          text: '回程单'
        }
      ]
    },
    {
      image: 'blate6Icon3',
      arr: [
        {
          cont: '骑手根据来单提醒，自主在抢单池抢单。',
          text: '抢单池抢单'
        },
        {
          cont: '运营者在调度后台调派平台订单，指定发送给接手接单。',
          text: '后台指派'
        },
        {
          cont: '团队开启智能调度，订单根据调度规则自动派发给最优骑手接单。',
          text: '自动接单'
        }
      ]
    },
    {
      image: 'blate6Icon5',
      arr: [
        {
          cont: '订单完成后支持用户打星和文字评价，对服务打分。',
          text: '系统评价'
        },
        {
          cont: '支持订单完成后用户对表现满意的骑手添加打赏。',
          text: '骑手打赏'
        },
        {
          cont: '订单完成后若对骑手服务不满意，支持用户投诉。',
          text: '骑手投诉'
        }
      ]
    },
    {
      image: 'blate6Icon4',
      arr: [
        {
          cont: '骑手配送过程中，取货送货位置支持拍照回传，确保安全送达。',
          text: '拍照回传'
        },
        {
          cont: '跑腿订单可设置收取件码，骑手配送过程中需要跟用户验证核对。',
          text: '收取件码'
        },
        {
          cont: '在调度后台会实时显示，骑手在配送过程中的实时路线定位。',
          text: '路线记录'
        }
      ]
    }
  ]
}

const blate7List = [
  {
    id: 1,
    title: '页面模板',
    leftData: [
      {
        label: '默认模板',
        image: 'blate7Image1'
      },
      {
        label: '大屏地图',
        image: 'blate7Image2'
      },
      {
        label: '简约模板',
        image: 'blate7Image3'
      },
      {
        label: '组件模板',
        image: 'blate7Image4'
      }
    ]
  },
  {
    id: 2,
    title: '场景自定义',
    leftData: [
      {
        label: '发单字段自定义',
        image: 'blate7Image5',
      },
      {
        label: '多种计费方式',
        image: 'blate7Image6'
      },
      {
        label: '支持附加费用',
        image: 'blate7Image7'
      },
      {
        label: '多种支付方式',
        image: 'blate7Image8'
      }
    ]
  },
  {
    id: 3,
    title: '代买店铺',
    leftData: [
      {
        label: '网红店铺',
        image: 'blate7Image9'
      },
      {
        label: '同城精选',
        image: 'blate7Image10'
      },
      {
        label: '预付跑腿费',
        image: 'blate7Image11'
      },
      {
        label: '货到付款',
        image: 'blate7Image12'
      }
    ]
  }
]

const blate8List = [
  {
    title: '用户端',
    cont: 'h5与小程序入口，无需用户下载，多种生活服务场景，便捷下单。',
    iconArr: [
      {
        icon: 'blate8List1',
        title: '页面模板'
      },
      {
        icon: 'blate8List2',
        title: '店铺代买'
      },
      {
        icon: 'blate8List3',
        title: '开屏广告'
      },
      {
        icon: 'blate8List4',
        title: '站点/定位选择'
      },
      {
        icon: 'blate8List5',
        title: '附近骑手'
      },
      {
        icon: 'blate8List6',
        title: '预计接单'
      },
      {
        icon: 'blate8List7',
        title: '邀请有礼'
      },
      {
        icon: 'blate8List8',
        title: '积分商城'
      },
      {
        icon: 'blate8List9',
        title: '好友代付'
      },
      {
        icon: 'blate8List10',
        title: '货到付款'
      }
    ]

  },
  {
    title: '商户端',
    cont: '商家独立APP，发单便捷，响应及时，所有订单数据皆可追溯。',
    iconArr: [
      {
        icon: 'blate8List11',
        title: '手动录单'
      },
      {
        icon: 'blate8List12',
        title: '拍照发单'
      },
      {
        icon: 'blate8List13',
        title: '二维码发单'
      },
      {
        icon: 'blate8List14',
        title: '常用地址'
      },
      {
        icon: 'blate8List15',
        title: 'WIFI打印'
      },
      {
        icon: 'blate8List16',
        title: '蓝牙打印'
      },
      {
        icon: 'blate8List17',
        title: '充值赠送'
      },
      {
        icon: 'blate8List18',
        title: '在线客服'
      },
      {
        icon: 'blate8List19',
        title: '余额提现'
      },
      {
        icon: 'blate8List20',
        title: '骑手打赏'
      }
    ]
  },
  {
    title: '骑手端',
    cont: '骑手独立APP，响铃及时，抢单、派单、转单快速响应。',
    iconArr: [
      {
        icon: 'blate8List21',
        title: '实名认证'
      },
      {
        icon: 'blate8List22',
        title: '平台保证金'
      },
      {
        icon: 'blate8List23',
        title: '奖惩制度'
      },
      {
        icon: 'blate8List24',
        title: '等级制度'
      },
      {
        icon: 'blate8List25',
        title: '排班制度'
      },
      {
        icon: 'blate8List26',
        title: '骑手保险'
      },
      {
        icon: 'blate8List27',
        title: '扫码录单'
      },
      {
        icon: 'blate8List28',
        title: '拍照回传'
      },
      {
        icon: 'blate8List29',
        title: '骑手投诉'
      },
      {
        icon: 'blate8List30',
        title: '回程单'
      }
    ]
  },
  {
    title: '调度后台',
    cont: '运营者管理调度中心，包含用户、商户、骑手管理与所有平台数据管理。',
    iconArr: [
      {
        icon: 'blate8List31',
        title: '场景配置'
      },
      {
        icon: 'blate8List32',
        title: '可视化操作'
      },
      {
        icon: 'blate8List33',
        title: '站点加盟'
      },
      {
        icon: 'blate8List34',
        title: '智能调度'
      },
      {
        icon: 'blate8List35',
        title: '计费设置'
      },
      {
        icon: 'blate8List36',
        title: '营销活动'
      },
      {
        icon: 'blate8List37',
        title: '预设地址'
      },
      {
        icon: 'blate8List38',
        title: '骑手监控'
      },
      {
        icon: 'blate8List39',
        title: '数据统计'
      },
      {
        icon: 'blate8List40',
        title: '经营分析'
      }
    ]
  }
]
let pageTimer = '' //停留时间

class ErrandDelivery extends React.Component {
  state = {
    phone:'',
    plate2Index: 0,
    baneerIndex:0,
    plate2Index2: 1, // 方案二index
    plate6Index: 0,
    plate7Index: 0,
    blate7LeftIndex: 0,
    blate7Image: 'blate7Image1'
  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'errands'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.com',
        page: 'errands',
        time: tempTimer
      },0)
    }
  }

  processToIndex = index => {
    this.setState({
      plate2Index: Number(index)
    })
  }
  processToIndex2 = index => {
    this.setState({
      plate2Index2: Number(index)
    })
  }
  goToIndex = index => {
    this.sliders.slick.slickGoTo(index)
    this.setState({
      plate6Index: Number(index)
    })
  }

  onClickBlate = (index, image) => {
    this.setState({
      blate7LeftIndex: Number(index),
      blate7Image: image
    })
  }

  slickGoToIndex = index => {
    this.slider.slick.slickGoTo(index)
    this.setState({
      plate7Index: Number(index),
      blate7LeftIndex: 0,
      blate7Image: blate7List[index].leftData[0].image
    })
  }
  render() {
    return (
      <div className="w-100" >
        <Helmet>
          <title>来云台-跑腿配送系统|同城跑腿APP|外卖配送系统|智能调度系统</title>
          <meta name="Description" content="来云台是一套适用于同城帮办、跑腿配送、货运搬家、代驾打车等多元化服务类型的系统，根据运营者业务需求支持自定义服务场景，满足多种跑腿代办需求，支持自由配置场景模块、计价规则、配送方式以及收费模式，便捷用户下单；开放API接口，支持绑定美团/饿了么自配送商家，自动发单；可划分多城市多区域运营，轻松布局、快速扩大自己的业务领域，帮助本地跑腿创业者打造自主品牌的跑腿配送平台。" />
          <meta name="Keywords" content="跑腿系统,配送软件,同城跑腿APP,智能调度系统"/>
        </Helmet>
        <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a)}) }}>
          <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
            <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex ===0, 'd-none': this.state.baneerIndex !== 0 })}>
              <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold"  }}><span style={{ color: '#F6BB07' }}>多元化服务场景</span>自定义配置 </div>
              <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>搭建创业者自主品牌的跑腿平台</span>
              <div className='Carousel-button d-flex animated fadeInRight'>
                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({phone:e.target.value})}} placeholder='请输入您的手机号' />
                <div onClick={()=> {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.com',
                    page: 'errands',
                    button: 'onTrial'
                  },0);
                  this.props.submit(this.state.phone)
                }} className='Carousel-btn'>立即试用</div>
              </div>
            </div>
          </div>
        </Carousel>
        {/**产品介绍  板块2**/}
        <div style={{ marginTop: '80px', background: '#ffffff' }}>
          <Title title="产品介绍"
            text="PRODUCT INTRODUCTION"
            width={710}
            height={66} />
          <div className={styles.plate2}>
            <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
              <img alt='' src={require(`../../asset/errand_delivery/plate2Image.png`)} style={{ width: '630px', height: '448px' }} />
              <div className={styles.plate2Item}>
                <span className={styles.plate2ItemTitle}>智能调度配送</span>
                <span className={styles.plate2Cont}>
                  是一套适用于同城帮办、跑腿配送、货运搬家、代驾打车等多元化服务类型的系统，根据运营者业务<br/>需求支持自定义服务场景，满足多种跑腿代办需求，支持自由配置场景模块、计价规则、配送方式以<br/>及收费模式，便捷用户下单。
                  <span className='d-block' style={{ height: '27px' }}></span>
                  开放API接口，支持绑定美团/饿了么自配送商家，自动发单；可划分多城市多区域运营，轻松布局、<br/>快速扩大自己的业务领域，帮助本地跑腿创业者打造自主品牌的跑腿配送平台。
                </span>
                <div className='d-flex' style={{ margin: '44px 0 40px -10px' }}>
                  {plate2.iconArr.map((item, indexs) => {
                    return (
                      <div className={styles.iconItem} key={indexs}>
                        <img alt='' src={require(`../../asset/errand_delivery/${item.icon}.png`)} style={{ width: '60px', height: '60px' }} />
                        <span>{item.text}</span>
                      </div>
                    )
                  })}
                </div>
                <div onClick={() => { 
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.com',
                    page: 'errands',
                    button: 'experience'
                  },0);
                  this.props.showDialog()
                }} className={styles.plate2Btn}>点击免费体验</div>
              </div>
            </div>
          </div>
        </div>
        {/**服务场景 板块3**/}
        {/* <div style={{ margin: '100px 0', background: '#ffffff' }}>
          <Title title="服务场景"
            text="SERVICE SCENARIO"
            width={710}
            height={66} />
          <img alt='' className='d-block' src={require(`../../asset/errand_delivery/plate4Image.png`)} style={{ width: '1440px', height: '720px', margin: '0 auto' }} />
        </div> */}
        {/**专注同城配送调度，提供配送解决方案 板块4**/}
        <div style={{ margin: '100px 0', background: '#ffffff' }}>
          <Title title="专注同城配送调度，提供配送解决方案"
            text="INTRA CITY DISTRIBUTION"
            width={710}
            height={66} />
          <img alt='' className='d-block' src={require(`../../asset/errand_delivery/plate5Image.png`)} style={{ width: '1440px', height: '720px', margin: '0 auto' }} />
        </div>
        {/**智能调度 板块5**/}
        <div style={{ margin: '100px 0', background: '#ffffff' }}>
          <Title title="智能调度"
            text="INTELLIGENT SCHEDULING"
            width={710}
            height={66} />
          <img alt='' className='d-block' src={require(`../../asset/errand_delivery/plate6Image.png`)} style={{ width: '1440px', height: '720px', margin: '0 auto' }} />
        </div>
        {/* 配送体系 板块6*/}
        <div style={{ margin: '100px 0', background: '#ffffff' }}>
          <Title
            title="配送体系"
            titleColor='#121213'
            text='DISTRIBUTION SYSTEM'
            width={591}
            height={68}
          />
          <div className={styles.blate6}>
            <div className={styles.blate6Box}>
              <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'flex-end', width: '355px' }}>
                {blate6List.leftData.map((item, index) => {
                  return (
                    <div key={index} onMouseOver={() => { this.goToIndex(index) }} className={this.state.plate6Index === index ? `${styles.blate6Left} ${styles.blate6LeftActive}` : `${styles.blate6Left}`}>
                      <img alt='' src={require(`../../asset/errand_delivery/${item.icon}.png`)} style={{ width: '36px', height: '36px' }} />
                      <span>{item.title}</span>
                    </div>
                  )
                })}
              </div>
              <div className={styles.blate6Carousel}>
                <Carousel dots={false} ref={slider => (this.sliders = slider)}>
                  {
                    blate6List.data.map((item, index) => {
                      return (
                        <dix key={index} className='d-flex'>
                          <div className='d-flex align-items-center' style={{ flexWrap: 'wrap', width: '490px', marginRight: '40px' }}>
                            {item.arr.map((row, indexs) => {
                              return (
                                <div key={indexs} className={styles.blate6CarouselItem}>
                                  <span className={styles.blate6CarouselItemText}>{row.text}</span>
                                  <span className={styles.blate6CarouselItemCont}>{row.cont}</span>
                                </div>
                              )
                            })}
                          </div>
                          <img alt="" src={require(`../../asset/errand_delivery/${item.image}.png`)} style={{ width: '474px', height: '638px' }} />
                        </dix>
                      )
                    })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        {/* 特色功能 板块7*/}
        <div style={{ margin: '100px 0 0', background: '#ffffff' }}>
          <Title title="特色功能"
            text="FEATURES"
            width={710}
            height={66} />
          <div className="d-flex justify-content-center  align-items-center" style={{ marginTop: '0px', marginBottom: '24px' }}>
            <div onMouseOver={() => { this.slickGoToIndex(0) }} className={classnames('home-plate2-btn1', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.plate7Index === 0 })}>
              页面模板
            </div>
            <span className="home-plate2-span"></span>
            <div onMouseOver={() => { this.slickGoToIndex(1) }} className={classnames('home-plate2-btn1', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.plate7Index === 1 })}>
              场景自定义
            </div>
            <span className="home-plate2-span"></span>
            <div onMouseOver={() => { this.slickGoToIndex(2) }} className={classnames('home-plate2-btn1', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.plate7Index === 2 })}>
              代买店铺
            </div>
          </div>
          <Carousel dots={false} ref={slider => (this.slider = slider)}>
            {
              blate7List.map((item, index) => {
                return (
                  <div key={index} className={styles.blate7}>
                    <div>
                      {item.leftData.map((row, blate7LeftIndex) => {
                        return (
                          <div key={blate7LeftIndex} onMouseOver={() => { this.onClickBlate(blate7LeftIndex, row.image) }} className={this.state.blate7LeftIndex === blate7LeftIndex ? `${styles.blate7Left} ${styles.blate7LeftActive} ` : `${styles.blate7Left}`}>
                            {row.label}
                          </div>
                        )
                      })

                      }
                    </div>
                    <div className={styles.blate7Right}>
                      <img alt="" src={require(`../../asset/errand_delivery/${this.state.blate7Image}.png`)} style={{ width: '884px', height: '426px' }} />
                    </div>
                  </div>
                )
              })}
          </Carousel>
        </div>
        {/**更多系统功能，满足平台多样性 板块8**/}
        <div style={{ margin: '100px 0', background: '#ffffff' }}>
          <Title title="更多系统功能，满足平台多样性"
            text="SYSTEM FUNCTION"
            width={710}
            height={66} />
          <div className={styles.blate8}>
            {blate8List.map((row, indexs) => {
              return (
                <div className={styles.blate8} key={indexs}>
                  <div className={styles.blate8Item}>
                    <div className={styles.blate8ItemTop}>
                      <span className={styles.topName}>{row.title}</span>
                      <span className={styles.topCont}>{row.cont}</span>
                    </div>
                    <div className={styles.iconArr}>
                      {row.iconArr.map((item, index) => {
                        return (
                          <div key={index} className={styles.iconArrItem}>
                            <img alt='' src={require(`../../asset/errand_delivery/${item.icon}.png`)} style={{ width: '80px', height: '80px' }} />
                            <span style={{ color: '#121213', fontSize: '16px' }}>{item.title}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {row.title !== '调度后台' && <span className={styles.blate7Line}></span>}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ErrandDelivery;