import React from 'react';
import ReactPlayer from 'react-player'

class manuell01 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>来云台外卖跑腿系统说明</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell01/manuell01.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell01/manuell01-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>什么是来云台外卖跑腿系统？</h3>
            <p>
              来云台外卖跑腿系统，是一款集外卖点餐、跑腿配送、智能调度、团购到店、物流电商、营销裂变及外卖柜云打印等软硬件服务于一体的综合性“互联网+”的本地生活服务平台智能系统；提供给创业者搭建一个属于自己品牌的“乡镇/同城/校园/多区域”外卖平台、跑腿配送平台；可利用来云台外卖跑腿系统，整合周边各行各业的商家到平台，打造本地居民衣食住行、吃喝玩乐购全方位掌上生活圈，占领本地移动端用户，成为本地移动互联入口，联通本地各行各业！整套系统包含了四个端（“调度管理后台端”是提供给平台团队运营者，拥有整个平台最高运营和管理权限，可以统筹管理和设置平台功能及业务，同时包括商家、骑手、用户的使用管理，类似某团管理后台；“商家端”是提供给入驻平台进行商品销售或业务服务的商家使用，可以上传商品、设置相关店铺运营功能及业务等，类似某团商家端；“骑手端”是提供给入驻平台进行订单配送或代跑腿服务的配送人员使用，可以系统化高效接单，智能规划配送服务等；“用户端”是提供给在平台上进行下单叫服务的用户使用，可以外卖点餐、商品代购、跑腿配送、业务代办、团购到店等相关生活服务）。
            </p>
            <img 
              alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell01/manuell01-2.png' 
              style={{width: '840px', display: 'block' ,marginBottom: '40px'}} />
            <p style={{padding: '15px 0'}}>
              系统内有团队、商家、骑手、用户这几类角色。<br/>
              ①团队：团队为平台管理者，可控制平台内所有其他角色，可自定义配置平台展示样式。
            </p>
            <img alt='' 
              src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell01/manuell01-3.png' 
              style={{width: '530px', display: 'block' ,marginBottom: '40px'}} />
            <p style={{padding: '15px 0'}}>②商家：商家可在商家端进行跑腿发单，如为外卖商家，则可设置外卖商品、并可在商家端进行外卖订单接单。</p>
            <img alt='' 
              src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell01/manuell01-5.png' 
              style={{width: '550px', display: 'block' ,marginBottom: '40px'}} />
            <p style={{padding: '15px 0'}}>③骑手：平台内一切需要骑手配送的订单，骑手都可在骑手端进行接单配送。</p>
            <img alt='' 
              src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell01/manuell01-4.png' 
              style={{width: '550px', display: 'block' ,marginBottom: '40px'}} />
            <p style={{padding: '15px 0'}}>④用户：指在平台小程序内进行登录的角色，可在小程序内进行跑腿、外卖、物流、团购下单。</p>
            <img alt='' 
              src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell01/manuell01-6.png' 
              style={{width: '550px', display: 'block' ,marginBottom: '0px'}} />
        </div>
    )
  }
}

export default manuell01;