import React from 'react';
import { Button, Carousel } from 'antd';
import styles from '../../css/offline_group_purchase.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import Title from '../../components/Title';
import TitleLeft from '../../components/TitleLeft';

// 板块二数据
const plate2List = [
    {
        title: '服务场景自定义',
        icon: 'plate2List1'
    },
    {
        title: '开放API接口',
        icon: 'plate2List2'
    },
    {
        title: '扫码快速录单',
        icon: 'plate2List3'
    },
    {
        title: '收取件码',
        icon: 'plate2List4'
    }
]

const plate3List = [
    {
        title: '餐饮美食',
        icon: 'plate3List1'
    },
    {
        title: '酒店民宿',
        icon: 'plate3List2'
    },
    {
        title: '景区门票',
        icon: 'plate3List3'
    },
    {
        title: '丽人美发',
        icon: 'plate3List4'
    },
    {
        title: '医学美容',
        icon: 'plate3List5'
    },
    {
        title: '瑜伽健身',
        icon: 'plate3List6'
    },
    {
        title: '休闲玩乐',
        icon: 'plate3List7'
    },
    {
        title: '电影演出',
        icon: 'plate3List8'
    }
]

const plate4List = [
    {
        title: '限时折扣',
        cont: '团购商品支持设置优惠的折扣价格，仅限线上购买、线下消费，限时营销模式激发用户的购买欲。',
    },
    {
        title: '优惠券',
        cont: '专为团购商品定制的优惠券，购买平台的团购订单，享受优惠折扣，包括折扣形式或现金抵扣。',
    },
    {
        title: '到店核销',
        cont: '用户线上购买团购商品后，订单中会显示二维码，线下消费时出示给商家，商家通过APP进行扫码核销。',
    }
]

const plate5List = [
    {
        title: '商家自定义发布',
        cont: '团购商品由商家自己发布，自定义设置套餐时间、价格、服务内容，发布后即可同步在平台团购模块，供用户自由选择。',
        image: 'plate5List1'
    },
    {
        title: '商品按需求分类',
        cont: '用户进入团购模块，可以根据定位即时展示距离最近的商家，也可以用户手动切换查看销量最高的商家。',
        image: 'plate5List2'
    },
    {
        title: '支持用户自由搜索',
        cont: '用户进入团购页面，可以根据自身的消费需求，手动输入关键词，查找平台上相关的团购商家及商品。',
        image: 'plate5List3'
    }
]

let pageTimer = '' //停留时间

class OfflineGroupPurchase extends React.Component {
    state = {
        phone: '',
        baneerIndex: 0,
    }
    componentWillMount() {
        // 获取进入页面的时间
        if (pageTimer === '') {
            pageTimer = parseInt(new Date().getTime() / 1000);
            console.log(pageTimer, 'pageTimer')
        }
        this.props.onVisitClick({
            type: 1,
            domain: 'www.qolai.com',
            page: 'group'
        }, 0)
    }

    componentWillUnmount() {
        // 离开时间减去进入时间
        const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
        console.log(tempTimer, 'tempTimer')
        // 清空时间戳
        pageTimer = '';
        if (tempTimer > 0) {
            this.props.onVisitClick({
                type: 2,
                domain: 'www.qolai.com',
                page: 'group',
                time: tempTimer
            }, 0)
        }
    }
    render() {
        return (
            <div className="w-100" >
                <Helmet>
                    <title>来云台-外卖团购|抖音团购|线下团购系统</title>
                    <meta name="Description" content="来云台线下团购系统借鉴大众点评、抖音团购的本地生活运营模式，打造引爆全网的线上引流到线下，为实体门店赋能;店团购是带动本地生活线下经济强有力的营销模式，通过线上传播的便利性，以优惠折扣吸引用户，从线上引流到线下，为本地门店导流，赋能与腾飞实体经济。" />
                    <meta name="Keywords" content="线下团购系统,抖音外卖团购,本地团购系统" />
                </Helmet>
                <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
                    <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
                        <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
                            <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>借鉴<span style={{ color: '#F6BB07' }}>大众点评、抖音团购</span>的本地生活运营模式</div>
                            <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>打造引爆全网的线上引流到线下，<span style={{ color: '#F6BB07' }}>为实体门店赋能</span></span>
                            <div className='Carousel-button d-flex animated fadeInRight'>
                                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({ phone: e.target.value }) }} placeholder='请输入您的手机号' />
                                <div onClick={() => { 
                                    this.props.onVisitClick({
                                        type: 3,
                                        domain: 'www.qolai.com',
                                        page: 'group',
                                        button: 'onTrial'
                                    },0);
                                    this.props.submit(this.state.phone)
                                 }} className='Carousel-btn'>立即试用</div>
                            </div>
                        </div>
                    </div>
                </Carousel>
                {/**来云台本地团购  板块2**/}
                <div style={{ background: '#FFFFFF', margin: '100px 0' }}>
                    <div className={styles.plate2}>
                        <img src={require('../../asset/offline_group_purchase/plate2Image1.png')} style={{ width: '630px', height: '448px' }} alt=''></img>
                        <div style={{ flex: 1, marginLeft: '40px' }}>
                            <TitleLeft title="来云台本地团购"
                                text="LOCAL GROUP"
                                width={600}
                                height={66}
                                marginBottom={30} />
                            <div style={{ fontSize: '16px', color: '#707070', marginBottom: '130px' }}>到店团购是带动本地生活线下经济强有力的营销模式，通过线上传播的便利性，以优惠折扣吸引用户，从线上引流到线下，为本地门店导流，赋能与腾飞实体经济。</div>
                            <div className='d-flex align-content-center flex-wrap'>
                                {
                                    plate2List.map((item, index) => {
                                        return (
                                            <div key={index} className={styles.plate2Item}>
                                                <img src={require(`../../asset/offline_group_purchase/${item.icon}.png`)} style={{ width: '60px', height: '60px' }} alt=''></img>
                                                <span>{item.title}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/**线下生活服务一网打尽  板块3**/}
                <div style={{ padding: '100px 0', background: '#F7F9FC' }}>
                    <Title title="线下生活服务一网打尽"
                        text="OFFLINE LIFE SERVICE"
                        width={710}
                        height={66}
                        marginBottom={30} />
                    <div className={styles.plate3}>
                        {
                            plate3List.map((item, index) => {
                                return (
                                    <div key={index} className={styles.plate3Item}>
                                        <img src={require(`../../asset/offline_group_purchase/${item.icon}.png`)} style={{ width: '80px', height: '80px' }} alt=''></img>
                                        <span>{item.title}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/**功能体系  板块4**/}
                <div style={{ background: '#FFFFFF', margin: '100px 0' }}>
                    <Title title="功能体系"
                        text="FUNCTIONNAL SYSTEM"
                        width={710}
                        height={66} />
                    <div className='d-flex justify-content-between align-content-center' style={{ width: '1280px', margin: '0 auto' }}>
                        <div style={{ flex: 1 }}>
                            {plate4List.map((item, index) => {
                                return (
                                    <div className={styles.plate4Item} key={index}>
                                        <div className={styles.plate4ItemTitle}>{item.title}</div>
                                        <div className={styles.plate4ItemCont}>{item.cont}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <img alt='' src={require(`../../asset/offline_group_purchase/plate4Image.png`)} style={{ width: '474px', height: '638px' }} />
                    </div>
                </div>
                {/**系统优势  板块5**/}
                <div style={{ background: '#F7F9FC', padding: '100px 0', marginBottom: '-100px' }}>
                    <Title title="系统优势"
                        text="SYSTEM ADVANTAGES"
                        width={710}
                        height={66} />
                    <div className='d-flex justify-content-center align-content-center'>
                        {plate5List.map((item, index) => {
                            return (
                                <div className={styles.plate5Item} key={index}>
                                    <img alt='' src={require(`../../asset/offline_group_purchase/${item.image}.png`)} style={{ width: '100%', height: '158px' }} />
                                    <div className={styles.plate5ItemTitle}>{item.title}</div>
                                    <div className={styles.plate5ItemCont}>{item.cont}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default OfflineGroupPurchase;