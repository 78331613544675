import React, {useEffect} from 'react';
import Carousel from 'element-react/dist/npm/es6/src/carousel';
import { Row, Col } from 'antd';
import 'element-theme-default';
import { customizedBlateThree } from '../../mock/index';
import { Helmet } from 'react-helmet';
import CarouselIndex from '../../components/Carousel';

// components
import Title from '../../components/Title';
// img alt=''
import title1 from '../../asset/customized/title1.png';
import title2 from '../../asset/customized/title2.png';

// function getQueryVariable(variable){
//   var query = window.location.search.substring(1);
//   if(window.location.hash){
//     return;
//   }
//   var vars = query.split("&");
//   for (var i=0;i<vars.length;i++) {
//           var pair = vars[i].split("=");
//           if(pair[0] === variable){return pair[1];}
//   }
//   return(false);
// } 
let pageTimer = '' //停留时间

function Customized(props) {
  // let id = '#' + getQueryVariable('id')
  // if(id !== "#undefined"){
  //   window.location.href = id
  // }
  useEffect(() => {
    // 获取进入页面的时间
    if (pageTimer === '') {
      pageTimer = parseInt(new Date().getTime() / 1000);
      console.log(pageTimer, 'pageTimer')
    }
    props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'custom'
    }, 0)
  
    // 这个返回的函数，会在该组件卸载时来执行
    // 因此，可以去执行一些清理操作，比如，解绑 window 的事件、清理定时器 等
    return () => {
      // 离开时间减去进入时间
      const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
      console.log(tempTimer, 'tempTimer')
      // 清空时间戳
      pageTimer = '';
      if (tempTimer > 0) {
        props.onVisitClick({
          type: 2,
          domain: 'www.qolai.com',
          page: 'custom',
          time: tempTimer
        }, 0)
      }
    }
  }, []);

  let onClickLink = () => {
    const newBridge = document.getElementById('newBridge')
    const nbInviteOk = document.getElementsByClassName('nb-invite-ok');
    if (newBridge && nbInviteOk) {
      nbInviteOk.click();
    }
  }
  const Carousel_text = [
    {
      carouselImage: 'customized/img_banner_bg03',
      text:[
        {text:'来云台 - ',colorText:''},
        {text:'打造',colorText:'个性化独立'},
        {text:'本地外卖跑腿平台',colorText:''}
      ],
      desc:'一站式系统定制服务，OEM专属品牌',
      scenes:[]
    }
  ]

  return (
    <div>
      <Helmet>
          <title>来云台-软件定制开发|品牌OEM|APP定制_小程序定制</title>
          <meta name="Description" content="盐城中联数科技术公司是提供移动互联网应用方案服务商,主要提供高端名片定制、微信商城定制、跑腿APP开发、校园跑腿系统、小程序专属定制一站式服务；一款满足新零售 O2O和传统跑腿+电商的配送系统，为跑腿公司拓展更多盈利模式, 对同城后三公里服务的跑腿提供解决方案，帮你打造属于自己的跑腿服务品牌" />
          <meta name="Keywords" content="小程序定制,品牌oem定制,APP定制,跑腿软件定制,OEM,软件定制开发" />
      </Helmet>
      {/**顶部banner**/} 
      <CarouselIndex data={Carousel_text} getIPs={props.getIPs} />
      {/**我们提供的服务**/}
      <div style={{paddingTop: '80px', paddingBottom: '80px'}} id="1">
        <Title 
          title="来云台 - 品牌个性化定制"
          img alt=''Url={title1}
          text='Customization'
          width={861}
          height={68}
        />
        <div className="d-flex justify-content-center">
          <div className="customized-card text-center" onClick={onClickLink}>
            <img alt='' src={require('../../asset/customized/icon2.png')} style={{width: '72px', height: '72px'}}/>
            <div className="text-center" style={{marginTop: '16px'}}>
              <span className="customized-card-span">品牌定制</span>
            </div>
            <div className="text-center">
              <p className="customized-card-p fw-600" >避免“模板化”千篇一律，从品牌logo、主题色、题图等传达出品牌独特的气质，在每一个细节之处与品牌特质相互呼应</p>
            </div>
          </div>
          <div className="customized-card text-center" onClick={onClickLink}>
            <img alt='' src={require('../../asset/customized/icon3.png')} style={{width: '72px', height: '72px'}}/>
            <div className="text-center" style={{marginTop: '16px'}}>
              <span className="customized-card-span">APP定制</span>
            </div>
            <div className="text-center">
              <p className="customized-card-p fw-600" >以用户个性化喜好为出发点，定制app的logo、名称、主题色、产品功能等内容</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="customized-card text-center" onClick={onClickLink}>
            <img alt='' src={require('../../asset/customized/icon4.png')} style={{width: '72px', height: '72px'}}/>
            <div className="text-center" style={{marginTop: '16px'}}>
              <span className="customized-card-span">短信/域名定制</span>
            </div>
            <div className="text-center">
              <p className="customized-card-p fw-600" >支持后台域名单独定制；支持用户、商户验证短信统一使用团队品牌，全方位塑造平台品牌形象。</p>
            </div>
          </div>
          <div className="customized-card text-center" onClick={onClickLink}>
            <img alt='' src={require('../../asset/customized/icon1.png')} style={{width: '72px', height: '72px'}}/>
            <div className="text-center" style={{marginTop: '16px'}}>
              <span className="customized-card-span">特色功能定制</span>
            </div>
            <div className="text-center">
              <p className="customized-card-p fw-600" >定制客户需求的特殊功能，提升服务质量，提高客户满意度，满足客户需求</p>
            </div>
          </div>
        </div>
      </div>
      <div className="caselist-plate2 caselist-plate-set" style={{backgroundImage:`url(${require('../../asset/customized/blate3bg.png')})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat'}} id="2">
        <Title 
          title="来云台 - 合作服务群体"
          text='Service objectives'
          titleColor='#FFFFFF'
          textColor=' #FFFFFF'
          imgUrl
          width={926}
          height={68}
        />
        <Row type="flex" justify="center">
          <Col className="plate2-item-box">
            <div className="plate2-item-main">
              <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/10(1).png' className="plate2-item-img alt=''"/>
              <span style={{color:'#333333'}} className="d-flex align-items-center justify-content-center mx-auto">本地创业者</span>
            </div>
            <div className="plate2-item-dec text-center">
              来云台外卖跑腿系统，适用于本地生活服务商<br />
              创立个性化的区域服务平台。<br />
              整合本地餐饮商家、生鲜小店、果蔬小铺、五<br />
              金店铺等本地实体店家，打造具有本地化特色<br />
              的自营外送平台。
            </div>
          </Col>
          <Col className="plate2-item-box">
            <div className="plate2-item-main">
              <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/10(2).png' className="plate2-item-img alt=''"/>
              <span style={{color:'#333333'}} className="d-flex align-items-center justify-content-center mx-auto">校园创业者</span>
            </div>
            <div className="plate2-item-dec text-center">
              针对校园市场，来云台外卖跑腿系统年轻化、<br />
              个性化深受高校创业者喜爱。<br />
              魔方导航后台可视化、自定义配置，可根据校<br />
              园的商家特色和服务类型定制平台需求模块，<br />
              帮助校园创业者快速拓展市场
            </div>
          </Col>
          <Col className="plate2-item-box">
            <div className="plate2-item-main">
              <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/10.png' className="plate2-item-img alt=''"/>
              <span style={{color:'#333333'}} className="d-flex align-items-center justify-content-center mx-auto">商超集团企业</span>
            </div>
            <div className="plate2-item-dec text-center">
              针对连锁超市，品牌商场，来云台定制站点加<br />
              盟形式，统一品牌口径与产品输出，同一小程<br />
              序，不同地域下单，后台统一管理。
            </div>
          </Col>
        </Row>
      </div>
      {/**我们的优势**/}
      <div className="customized-blate3" id="3" style={{paddingTop: '100px'}}>
        <Title 
          title="来云台 - 我们的优势"
          text='INDUSTRY NEWS'
          imgUrl={title2}
          width={768}
          height={68}
        /> 
        <div className="containers">
          <Carousel autoplay={false} interval="4000" type="card" height="368px">
            {
              customizedBlateThree.map((item, index) => {
                return (
                  <Carousel.Item>
                    <div className="customized-carousel" key={index}>
                      <div className="text-center">
                        <img alt='' src={require(`../../asset/customized/${item.icon}.png`)} style={{height: '100px', width: '100px'}} />
                      </div>
                      <p style={{fontSize: '22px', color: '#35373D', fontWeight: 'bold', lineHeight: '60px'}} className="text-center m-0">{item.title}</p>
                      <p style={{fontSize: '16px', fontWeight: '600', lineHeight: '27px', padding: '0 50px'}} className="mx-auto text-center my-0">
                        {item.dec}
                      </p>
                    </div>
                  </Carousel.Item>
                )
              })
            }
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Customized;