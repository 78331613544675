import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/delivery_platform.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import Title from '../../components/Title';

// 板块二数据
const plate2 = {
  tabs: [
    {
      title: '用户端',
      tip: '微信、抖音、头条小程序+H5'
    },
    {
      title: '商家',
      tip: 'APP'
    },
    {
      title: '商家后台',
      tip: 'PC端'
    },
    {
      title: ' 骑手',
      tip: 'APP'
    },
    {
      title: ' 调度后台',
      tip: 'PC端'
    }
  ],
  plate2List: [
    {
      title: '用户端',
      cont: '清晰便捷的下单入口，丰富多样的营销玩法，升级用户体验。',
      image: 'plate2List1',
      data: ['√ 丰富页面模板自由选择，页面模块自定义配置，可视化操作', '√ 支持设置二级页面、超市店铺，细分商家与平台服务', '√ 改为“支持多平台入驻，微信、抖音、头条，紧跟时代潮流”', '√ 支持外卖配送、物流配送、聚合配送、用户自提、团购到店', '√ 多城市区域加盟，站点自由切换']
    },
    {
      title: '商家APP',
      cont: '',
      image: 'plate2List2',
      data: ['√ 支持多品牌小票/标签打印机链接，包括wifi和蓝牙打印', '√ 商户钱包余额充值，支持充值赠送', '√ 支持商家自动/手动接单，来单自动打印', '√ 多种营销折扣活动商家自定义设置', '√ 团购到店商品扫码核销']
    },
    {
      title: '商家后台',
      cont: '便捷商户订单、商品、分类等设置，详细经营数据分析',
      image: 'plate2List3',
      data: ['√ 商家基础信息设置，编辑操作，可视化操作', '√ 订单按需快速检索，快速回复用户评价', '√ 账户资金管理与明细，实时对账查账', '√ 支持商户订单、营销等数据导出', '√ 商家外卖经营数据分析，实时掌握方便库存核对']
    },
    {
      title: '骑手端',
      cont: '',
      image: 'plate2List4',
      data: ['√ 支持设置骑手接单前身份实名认证，缴纳平台保证金', '√ 可设立骑手奖惩制度、等级制度、排班制度、骑手保险', '√ 支持骑手扫码录单、拍照回传，提升配送效率与准确度', '√ 骑手路线实时定位记录，支持回程单接单设置', '√ 骑手公告、骑手通知，提升平台管理骑手的效率']
    },
    {
      title: '管理后台',
      cont: '',
      image: 'plate2List5',
      data: ['√ 可视化DIY页面配置，精美模板一键使用', '√ 添加商户、骑手进入团队，设置权限', '√ 自定义设置平台计价方案与骑手佣金制度', '√ 平台财务数据化展示，拥有提现打款权限', '√ 自定义设置员工身份和管理权限，添加员工']
    }
  ]
}

const plate3 = {
  plate3OneItem: [
    {
      title: '默认模板',
      image: 'plate3OneItem1'
    },
    {
      title: '节日模板',
      image: 'plate3OneItem2'
    },
    {
      title: '优惠模板',
      image: 'plate3OneItem3'
    },
    {
      title: '本地生活',
      image: 'plate3OneItem4'
    },
    {
      title: '服饰百货',
      image: 'plate3OneItem5'
    },
    {
      title: '二级页面',
      image: 'plate3OneItem6'
    }
  ],
  plate3TwoItem: [
    {
      title: '默认装修',
      image: 'plate3TwoItem1'
    },
    {
      title: '视频背景',
      image: 'plate3TwoItem2'
    },
    {
      title: '营销动图',
      image: 'plate3TwoItem3'
    },
    {
      title: '推荐商品',
      image: 'plate3TwoItem4'
    }
  ]
}

const plate4List = [
  {
    data: [
      {
        title: '二级页面',
        icon: 'plate4icon1'
      },
      {
        title: '广告模块',
        icon: 'plate4icon2'
      },
      {
        title: '魔方导航',
        icon: 'plate4icon3'
      },
      {
        title: '单独推荐',
        icon: 'plate4icon4'
      },
      {
        title: '批量推荐',
        icon: 'plate4icon5'
      },
      {
        title: '公告/搜索栏',
        icon: 'plate4icon6'
      },
      {
        title: '天天特价',
        icon: 'plate4icon7'
      },
      {
        title: '团购模块',
        icon: 'plate4icon8'
      }
    ]
  },
  {
    data: [
      {
        title: '智能调度',
        icon: 'plate4icon9'
      },
      {
        title: '手动派单',
        icon: 'plate4icon10'
      },
      {
        title: '骑手抢单',
        icon: 'plate4icon11'
      },
      {
        title: '骑手转单',
        icon: 'plate4icon12'
      },
      {
        title: '强制指派',
        icon: 'plate4icon13'
      },
      {
        title: '回程单',
        icon: 'plate4icon14'
      },
      {
        title: '骑手路线',
        icon: 'plate4icon15'
      },
      {
        title: '位置追踪',
        icon: 'plate4icon16'
      }
    ]
  },
  {
    data: [
      {
        title: '实名认证',
        icon: 'plate4icon17'
      },
      {
        title: '平台保证金',
        icon: 'plate4icon18'
      },
      {
        title: '奖惩制度',
        icon: 'plate4icon19'
      },
      {
        title: '骑手等级',
        icon: 'plate4icon20'
      },
      {
        title: '骑手排班',
        icon: 'plate4icon21'
      },
      {
        title: '骑手保险',
        icon: 'plate4icon22'
      },
      {
        title: '骑手申诉',
        icon: 'plate4icon23'
      },
      {
        title: '定位回传',
        icon: 'plate4icon24'
      }
    ]
  }
]

const plate5 = {
  tabs: [
    {
      title: '拉新获客',
      icon: 'plate5Icon1'
    },
    {
      title: '刺激下单',
      icon: 'plate5Icon2'
    },
    {
      title: '提升复购',
      icon: 'plate5Icon3'
    },
    {
      title: '增加黏性',
      icon: 'plate5Icon4'
    }
  ],
  plate5List: [
    {
      image: 'plate5List1',
      data: [
        {
          title: '邀请有礼',
          cont: '邀请新人下单获得佣金奖励，带动客户分享情绪，实现客户的裂变。',
        },
        {
          title: '新客立减',
          cont: '单独针对新人的优惠活动，可设置大额减免，提升传播，增加用户基数。',
        },
        {
          title: '新人优惠券',
          cont: '新人专享的优惠券，可以是折扣券，也可以是现金券，增加平台用户。',
        },
        {
          title: '拼好饭',
          cont: '拼单点外卖享受优惠，用户参与活动后可分享给好友，邀请用户。',
        }
      ]
    },
    {
      image: 'plate5List2',
      data: [
        {
          title: '满减活动',
          cont: '设立营销门槛，用户下单达满减值即可享受平台减免优惠动，可设置多个满减梯队。',
        },
        {
          title: '第二件半价',
          cont: '购第二件享受半价优惠，薄利多销的运营理念提升平台的销量和销售额。',
        },
        {
          title: '随机购物',
          cont: '随机展示平台的优质商品，可直接购买或刷新切换，刺激用户下单。',
        },
        {
          title: '减配送费',
          cont: '可设置配送费直接减免活动，下单即可免配送费，由商家或团队承担。',
        }
      ]
    },
    {
      image: 'plate5List3',
      data: [
        {
          title: '超级会员',
          cont: '融合红包售卖、优惠券、会员积分的营销元素，锁定用户长期消费，支持兑换店铺更大额红包。',
        },
        {
          title: '营销广告',
          cont: '系统内多个页面均可设置弹窗广告，可给高品质、高性价比店铺多做营销宣传。',
        },
        {
          title: '天天特价',
          cont: '展示平台内当日所有设置了折扣商品的商家商品，吸引更多用户下单，打造店铺爆款。',
        },
        {
          title: '优惠券',
          cont: '可设置发放新人券、折扣券、满减券、指定券多等多种类型的优惠券，吸引用户不断复购。',
        }
      ]
    },
    {
      image: 'plate5List4',
      data: [
        {
          title: '营销签到',
          cont: '用户签到可获取积分奖励，提升用户打开平台的频率，增加用户黏性。',
        },
        {
          title: '积分商城',
          cont: '支持签到、消费获取平台积分，可用于下单抵扣或兑换平台奖励。',
        },
        {
          title: '分享有礼',
          cont: '用户分享小程序给好友可获得优惠券奖励，可用于平台下单享受折扣优惠。',
        },
        {
          title: '充值赠送',
          cont: '用户充值赠送奖励现金或者优惠券，有效锁定用户在平台长期消费。',
        }
      ]
    }
  ]
}
const plate6List = [
  {
    title: '社会市场',
    cont: '某外国语大学校内学生创业者自建的外卖点餐平台,整合了校内外餐饮美食商家,开通表白墙信息发布,搭建校内信息交流平台。',
    image: 'plate6List1'
  },
  {
    title: '校园市场',
    cont: '云南某乡镇外卖平台,当地还未被美团饿了么覆盖,搭建了本地的外卖平台,专注于乡镇外卖服务,目前已开通3个乡镇运营。',
    image: 'plate6List2'
  },
  {
    title: '国外市场',
    cont: '白俄罗斯华侨通过来云台系统搭建的集外卖、跑腿、团购、信息发布于一体的本地生活服务平台,集吃喝玩乐购,打造便捷移动掌上生活圈。',
    image: 'plate6List3'
  }
]

let pageTimer = '' //停留时间
class DeliveryPlatform extends React.Component {
  state = {
    phone: '',
    baneerIndex: 0,
    plate2Index: 0,
    plate2Index2: 1, // 方案二index
    plate3OneIndex: 0,
    plate3TwoIndex: 0,
    plate4Index: 0,
    plate5Index: 0,
    plate3OneImg: 'plate3OneItem1',
    plate3TwoImg: 'plate3TwoItem1'
  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'food'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.com',
        page: 'food',
        time: tempTimer
      },0)
    }
  }

  slickGoToIndex = index => {
    this.slider.slick.slickGoTo(index)
    this.setState({
      plate2Index: Number(index)
    })
  }
  onPlate3OneItem = (index, image) => {
    this.setState({
      plate3OneIndex: Number(index),
      plate3OneImg: image
    })
  }

  onPlate3TwoItem = (index, image) => {
    this.setState({
      plate3TwoIndex: Number(index),
      plate3TwoImg: image
    })
  }

  slick4Index = index => {
    this.sliders.slick.slickGoTo(index)
    this.setState({
      plate4Index: Number(index)
    })
  }

  slick5Index = index => {
    this.slider5.slick.slickGoTo(index)
    this.setState({
      plate5Index: Number(index)
    })
  }
  render() {
    return (
      <div className="w-100">
        <Helmet>
          <title>来云台-外卖平台系统|校园外卖小程序|乡镇外卖|抖音外卖</title>
          <meta name="Description" content="来云台外卖平台系统，包含：用户端（小程序）+骑手端（APP）+商家端（PC+APP）+后台PC端（PC+APP），为区域化运营提供强有力技术服务支撑；助力县城、同城、乡镇、校园外卖创业者打造类似美团/饿了么的优质外卖平台，致力于实现本地生活信息化，提供全套解决方案。" />
          <meta name="Keywords" content="外卖平台系统,外卖系统,校园乡镇外卖,外卖小程"/>
        </Helmet>
        <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
          <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
            <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
              <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>打造<span style={{ color: '#F6BB07' }}>类似美团/饿了么</span>的优质外卖平台</div>
              <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>致力于实现本地生活信息化，提供全套解决方案</span>
              <div className='Carousel-button d-flex animated fadeInRight'>
                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({phone:e.target.value})}} placeholder='请输入您的手机号' />
                <div onClick={()=> {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.com',
                    page: 'food',
                    button: 'onTrial'
                  },0);
                  this.props.submit(this.state.phone)
                }} className='Carousel-btn'>立即试用</div>
              </div>
            </div>
          </div>
        </Carousel>
        {/**终端介绍  板块2**/}
        <div style={{ margin: '100px 0', background: '#ffffff' }}>
          <Title title="终端介绍"
            text="TERMINAL INTRODUCTION"
            width={710}
            height={66} />
          <div className={styles.plate2}>
            <div className="d-flex justify-content-center  align-items-center">
              {plate2.tabs.map((row, index) => {
                return (
                  <div key={index} onClick={() => { this.slickGoToIndex(index) }} className={this.state.plate2Index === index ? `${styles.plate2TabsActive} ${styles.plate2Tabs}` : `${styles.plate2Tabs}`}>
                    <span className={this.state.plate2Index === index ? `${styles.plate2TabsTitleActive} ${styles.plate2TabsTitle}` : `${styles.plate2TabsTitle}`}>{row.title}</span>
                    <span className={styles.plate2TabsTip}>{row.tip}</span>
                    <span className={this.state.plate2Index === index ? `${styles.plate2TabsLineActive} ${styles.plate2TabsLine}` : `${styles.plate2TabsLine}`}></span>
                  </div>
                )
              })}
            </div>
            <Carousel dots={false} ref={slider => (this.slider = slider)} style={{ padding: '80px 0 0px' }}>
              {
                plate2.plate2List.map((item, index) => {
                  return (
                    <div key={index} className='d-flex align-items-center'>
                      <img src={require(`../../asset/delivery_platform/${item.image}.png`)} style={{ width: '720px', height: '500px' }} alt=''></img>
                      <div className={styles.plate2Content}>
                        <div className={styles.plate2ContentTitle}>{item.title}</div>
                        {item.cont && <div className={styles.plate2ContentCont}>{item.cont}</div>}
                        {
                          item.data.map((row, indexs) => {
                            return (
                              <div key={indexs} className={styles.plate2ContentData}>{row}</div>
                            )
                          })
                        }
                        {/* <div onClick={()=>{
                          this.props.onVisitClick({
                            type: 3,
                            domain: 'www.qolai.com',
                            page: 'food',
                            button: 'consultingService'
                          },() => window.location.href = 'https://p.qiao.baidu.com/cps/chat?siteId=14346789&userId=27807426&siteToken=84dc608b5e58978aa34999b39897161a&cp=qolai.com&cr&cw');
                        }}><div className={styles.plate2Btn}>购买咨询</div></div> */}
                      </div>
                    </div>
                  )
                })}
            </Carousel>
          </div>
        </div>
        {/**终端介绍  板块3**/}
        <div style={{ padding: '100px 0', background: '#F7F9FC' }}>
          <Title title="页面自定义"
            text="PAGE CUSTOMIZATION"
            width={710}
            height={66} />
          <div className={styles.plate3}>
            <img src={require(`../../asset/delivery_platform/${this.state.plate3OneImg}.png`)} style={{ width: '330px', height: '660px' }} alt=''></img>
            <div style={{ flex: 1 }} className={styles.plate3Content}>
              <div className={styles.plate3ContentOne}>
                <div>
                  <span className={styles.plate3ContentTitle} style={{ marginBottom: '17px' }}>外卖模板</span>
                  <div className={styles.plate3OneItem}>
                    {
                      plate3.plate3OneItem.map((item, index) => {
                        return (
                          <span key={index} onMouseMove={() => { this.onPlate3OneItem(index, item.image) }} className={this.state.plate3OneIndex === index ? `${styles.plate3ItemActive} ${styles.plate3Item}` : `${styles.plate3Item}`}>{item.title}</span>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className={styles.plate3ContentTwo}>
                <div>
                  <span className={styles.plate3ContentTitle} style={{ marginBottom: '28px' }}>店铺装修</span>
                  <div className={styles.plate3TwoItem}>
                    {
                      plate3.plate3TwoItem.map((item, index) => {
                        return (
                          <span key={index} onMouseMove={() => { this.onPlate3TwoItem(index, item.image) }} className={this.state.plate3TwoIndex === index ? `${styles.plate3ItemActive} ${styles.plate3Item}` : `${styles.plate3Item}`}>{item.title}</span>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            <img src={require(`../../asset/delivery_platform/${this.state.plate3TwoImg}.png`)} style={{ width: '330px', height: '660px' }} alt=''></img>
          </div>
        </div>
        {/**多种跑腿服务场景，打造“懒人经济”下的绝佳体验  板块4**/}
        <div style={{ margin: '100px auto 40px', background: '#ffffff', width: '1440px' }}>
          <Title title="来云台外卖跑腿系统 — 专注本地生活平台打造"
            text="BUILDING OF LIVING PLATFORM"
            width={710}
            height={66} />
          <div className="d-flex justify-content-center  align-items-center" style={{ marginBottom: '50px' }}>
            {['模块配置', '调度配送', '骑手管理'].map((item, index) => {
              return (
                <>
                  <div onMouseOver={() => { this.slick4Index(index) }} style={{ width: '310px' }} className={classnames('home-plate2-btn1', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.plate4Index === index })}>
                    {item}
                  </div>
                  {item !== '骑手管理' && <span className="home-plate2-span"></span>}
                </>
              )
            })}
          </div>
          <Carousel dots={false} ref={slider => (this.sliders = slider)}>
            <div className={styles.plate4}>
              {plate4List[this.state.plate4Index].data.map((item, index) => {
                return (
                  <div key={index} className={styles.plate4Item}>
                    <img src={require(`../../asset/delivery_platform/${item.icon}.png`)} style={{ width: '80px', height: '80px', marginBottom: '16px' }} alt=''></img>
                    <span>{item.title}</span>
                  </div>
                )
              })}
            </div>
          </Carousel>
        </div>
        {/**特色功能介绍  板块5**/}
        <div style={{ padding: '90px 0 100px', background: '#F7F9FC' }}>
          <Title title="特色功能介绍"
            text="INTRODUCTION TO FEATURED FUNCTIONS"
            width={710}
            height={66} />
          <div className={styles.plate5}>
            <div className="d-flex align-items-center" style={{ borderBottom: '2px solid #E2E2E2', justifyContent: 'space-between' }}>
              {plate5.tabs.map((row, index) => {
                return (
                  <div key={index} onClick={() => { this.slick5Index(index) }} className='d-flex align-items-center' style={{cursor: 'pointer'}}>
                    <div className={this.state.plate5Index === index ? `${styles.plate2TabsActive} ${styles.plate2Tabs}` : `${styles.plate2Tabs}`}>
                      <div className='d-flex align-items-center' style={{ marginBottom: '20px', justifyContent: 'center' }}>
                        <div className='d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', marginRight: '10px',borderRadius: '10px', background: this.state.plate5Index === index ? '#F6BB07' : '#F8F8F8'}}>
                          <img className='d-block' src={require(`../../asset/delivery_platform/${row.icon}.png`)} style={{ width: '40px', height: '40px' }} alt=''></img>
                        </div>
                        <span className={this.state.plate5Index === index ? `${styles.plate5TabsTitle} ${styles.plate2TabsTitleActive}` : `${styles.plate5TabsTitle}`}>{row.title}</span>
                        {row.title !== '增加黏性' && <img className='d-block' src={require(`../../asset/delivery_platform/arror.png`)} style={{ width: '40px', height: '40px', margin: '0 44px 0 42px' }} alt=''></img>}
                      </div>
                      <span className={this.state.plate5Index === index ? `${styles.plate5TabsLine} ${styles.plate5TabsLineActive}` : `${styles.plate5TabsLine}`}></span>
                    </div>
                  </div>
                )
              })}
            </div>
            <Carousel dots={false} ref={slider => (this.slider5 = slider)}>
              {
                plate5.plate5List.map((item, index) => {
                  return (
                    <div key={index} className='d-flex align-items-center justify-content-center'>
                      <div className='d-flex' style={{ flexDirection: 'column', flex: 1 }}>
                        {
                          item.data.map((row, indexs) => {
                            return (
                              <div key={indexs} className={styles.plate5ContentData}>
                                <span style={{ fontSize: '28px', fontWeight: '700', marginBottom: '14px', color: '#121213' }}>{row.title}</span>
                                <span style={{ fontSize: '21px', color: '#707070' }}>{row.cont}</span>
                              </div>
                            )
                          })
                        }
                      </div>
                      <img src={require(`../../asset/delivery_platform/${item.image}.png`)} style={{ width: '480px', height: '564px', margin: '32px 0 10px' }} alt=''></img>
                    </div>
                  )
                })}
            </Carousel>
          </div>
        </div>
        {/**案例分析  板块6**/}
        <div style={{ background: '#FFFFFF', padding: '100px 0 45px' }}>
          <Title title="案例分析"
            text="CASE ANALYSIS"
            width={710}
            height={66} />
          <div className={styles.plate6}>
            {plate6List.map((item, index) => {
              return (
                <div className={styles.plate6Item} key={index}>
                  <img alt='' src={require(`../../asset/delivery_platform/${item.image}.png`)} style={{ width: '100%', height: '158px' }} />
                  <div className={styles.plate6ItemTitle}>{item.title}</div>
                  <div className={styles.plate6ItemCont}>{item.cont}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryPlatform;