import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/site_joining.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import Title from '../../components/Title';
import TitleLeft from '../../components/TitleLeft';

// 板块二数据
const plate2List = ['√ 共用同一小程序', '√ 站点自由切换', '√ 支持管理本地商家', '√ 分站骑手独立管理', '√ 支持管理本地商家', '√ 分站城市自由添加', '√ 分站商家独立结算', '√ 平台资金统一管理']

const plate4List = [
    {
        icon: 'plate4List1',
        title: '商家入驻费',
        text: '针对每位入驻平台的商家，运营者可每收取商家平台入驻费，商家按年支付，形成平台固定收入产值。'
    },
    {
        icon: 'plate4List2',
        title: '订单抽成',
        text: '邀请商户入驻外卖平台，与商家达成协议，就平台订单交易金额，按照比例抽取固定佣金。'
    },
    {
        icon: 'plate4List3',
        title: '广告收益',
        text: '外卖平台内有开屏广告、魔方导航营销图、banner轮播等丰富广告位，定制投放方案，商家付费推广。'
    },
    {
        icon: 'plate4List4',
        title: '配送抽成',
        text: '外卖平台打通配送系统，每单配送费用抽取一定比例或者固定配送佣金，多渠道获利。'
    },
    {
        icon: 'plate4List5',
        title: '站点加盟',
        text: '多城市、多区域站点加盟，分站点页面独立配置，收益独立结算，增加平台固定收益。'
    }
]

const plate5List = [
    {
        icon: 'plate5Image1',
        title: '领先技术水平',
        text: '专注本地O2O系统相关领域开发，累积多年行业经验，提供完善的解决方案'
    },
    {
        icon: 'plate5Image2',
        title: '资深开发团队',
        text: '多年软件工程师领队开发，产品、UI、开发、测试、运维一站式专业团队'
    },
    {
        icon: 'plate5Image3',
        title: '数据安全保障',
        text: '平台数据严谨加密处理，数据信息独立安全，为客户做好安全护盾'
    },
    {
        icon: 'plate5Image4',
        title: '专业运维技术',
        text: '资深的运维人员，定期服务日志排查与防护监控，阿里云服务服务器保障支撑'
    },
    {
        icon: 'plate5Image5',
        title: '聆听客户建议',
        text: '积极听取客户前线意见，认真采纳有效建议并及时安排在开发排程中'
    },
    {
        icon: 'plate5Image6',
        title: '多终端覆盖',
        text: '系统全套包含公众号H5、小程序、APP（Android+IOS）、PC后台，数据互通'
    },
    {
        icon: 'plate5Image7',
        title: '多站点城市加盟',
        text: '支持开通不同城市/区域站点，分站独立设置，总站统一管理，站点自由切换'
    },
    {
        icon: 'plate5Image8',
        title: '高效迭代更新',
        text: '专业SaaS服务，免费提供技术支持与运营维护，产品保持高频更新迭代'
    },
    {
        icon: 'plate5Image9',
        title: '一对一售后服务',
        text: '匹配专人售后客服服务，5*12h一对一远程服务，在线解决用户问题'
    },
    {
        icon: 'plate5Image10',
        title: '支持定制开发',
        text: '针对不同客户运营模式需求，可提供专属定制开发服务，定制特殊需求功能'
    }
]

let pageTimer = '' //停留时间

class SiteJoining extends React.Component {
    state = {
        phone: '',
        baneerIndex: 0,
    }
    componentWillMount() {
        // 获取进入页面的时间
        if (pageTimer === '') {
            pageTimer = parseInt(new Date().getTime() / 1000);
            console.log(pageTimer, 'pageTimer')
        }
        this.props.onVisitClick({
            type: 1,
            domain: 'www.qolai.com',
            page: 'sjoining'
        }, 0)
    }

    componentWillUnmount() {
        // 离开时间减去进入时间
        const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
        console.log(tempTimer, 'tempTimer')
        // 清空时间戳
        pageTimer = '';
        if (tempTimer > 0) {
            this.props.onVisitClick({
                type: 2,
                domain: 'www.qolai.com',
                page: 'sjoining',
                time: tempTimer
            }, 0)
        }
    }
    render() {
        return (
            <div className="w-100">
                <Helmet>
                    <title>来云台-外卖跑腿系统|多站点外卖|多站点跑腿|外卖配送小程序</title>
                    <meta name="Description" content="来云台外卖跑腿系统支持平台创业者业务发展开后，开设多城市、多区域站点加盟，总站拥有最高权限，给分站下放权限,包括授权时间、功能模块、功能插件等等，用户进入平台小程序，优先根据定位进入到最近的运营站点，支持手动自由切换等，来云台专注打造本地/校园平台吃喝玩乐购一体式生活圈。" />
                    <meta name="Keywords" content="外卖跑腿系统,多站点外卖配送,外卖跑腿小程序" />
                </Helmet>
                <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
                    <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
                        <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
                            <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>支持同一平台多城市、多区域站点加盟</div>
                            <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>专注打造<span style={{ color: '#F6BB07' }}>本地/校园平台吃喝玩乐购</span>一体式生活圈</span>
                            <div className='Carousel-button d-flex animated fadeInRight'>
                                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({ phone: e.target.value }) }} placeholder='请输入您的手机号' />
                                <div onClick={() => { 
                                    this.props.onVisitClick({
                                        type: 3,
                                        domain: 'www.qolai.com',
                                        page: 'sjoining',
                                        button: 'onTrial'
                                      },0);
                                    this.props.submit(this.state.phone) 
                                }} className='Carousel-btn'>立即试用</div>
                            </div>
                        </div>
                    </div>
                </Carousel>
                {/**来云台多站点加盟系统  板块2**/}
                <div style={{ background: '#FFFFFF', margin: '100px 0' }}>
                    <div className={styles.plate2}>
                        <img src={require('../../asset/site_joining/plate2Image1.png')} style={{ width: '720px', height: '500px' }} alt=''></img>
                        <div style={{ flex: 1, marginLeft: '143px' }}>
                            <TitleLeft title="来云台多站点加盟系统"
                                text="MULTI SITE JOINING"
                                width={500}
                                height={66}
                                marginBottom={40} />
                            <div style={{ fontSize: '20px', color: '#707070', marginBottom: '33px' }}>
                                支持平台创业者业务发展开后，开设多城市、多区域站点加盟，<br />
                                总站拥有最高权限，给分站下放权限,包括授权时间、功能模块、<br />
                                功能插件等等，用户进入平台小程序，优先根据定位进入到最<br />
                                近的运营站点，支持手动自由切换。
                            </div>
                            <div className='d-flex justify-content-between align-content-center flex-wrap'>
                                {
                                    plate2List.map((item, index) => {
                                        return (
                                            <div key={index} className={styles.plate2Item}>{item}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/**多种合作模式  板块3**/}
                <div style={{ padding: '100px 0 0', background: '#F7F9FC' }}>
                    <Title title="多种合作模式"
                        text="MULTIPLE COOPERATION MODES"
                        width={710}
                        height={66} />
                    <img src={require('../../asset/site_joining/plate3Image1.png')} style={{ width: '1440px', height: '720px', display: 'block', margin: '0 auto' }} alt=''></img>
                </div>
                {/**获利指导  板块4**/}
                <div style={{ background: '#FFFFFF', margin: '100px 0' }}>
                    <Title title="获利指导"
                        text="PROFIT GUIDANCE"
                        width={710}
                        height={66} />
                    <div className={styles.plate4}>
                        {plate4List.map((item, index) => {
                            return (
                                <div key={index} className={styles.plate4Item}>
                                    <div className={styles.plate4ItemTop}>
                                        <div className={styles.plate4ItemTopItem}>
                                            <img alt='' src={require(`../../asset/site_joining/${item.icon}.png`)} style={{ width: '50px', height: '50px' }} />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '33px',
                                            fontSize: '20px',
                                            color: '#333333',
                                            marginBottom: '23px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {item.title}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            color: '#707070',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {item.text}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/**为什么选择来云台外卖系统  板块5**/}
                <div style={{ background: '#FFFFFF', margin: '100px 0' }}>
                    <Title title="为什么选择来云台外卖系统"
                        text="WHY CHOOSE"
                        width={710}
                        height={66}
                        marginBottom={20} />
                    <div
                        style={{
                            fontSize: '20px',
                            color: '#707070',
                            textAlign: 'center',
                            marginBottom: '22px'
                        }}>
                        专注本地O2O系统领域，多年的运营实战与开发服务经验，强大的技术开发团队，全方位产品功能优势，为外卖<br />
                        平台运营商提供完善的业务解决方案
                    </div>
                    <div className={styles.plate5}>
                        {plate5List.map((item, index) => {
                            return (
                                <div key={index} className={styles.plate5Item}>
                                    <img alt='' src={require(`../../asset/site_joining/${item.icon}.png`)} style={{ width: '80px', height: '80px' }} />
                                    <div
                                        style={{
                                            fontSize: '24px',
                                            color: '#121213',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {item.title}
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '20px',
                                            fontSize: '16px',
                                            color: '#707070',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {item.text}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default SiteJoining;