import React from 'react';
import { Row, Col, Icon, message } from 'antd';
import {
  saveOfficialFrom
} from '../apis/App';
const style = {
  fontSize: '12px',
  color: '#AEAFB4',
  marginRight: 5
}
const footer = {
  minWidth: '1300px',
  minHeight: 60,
  background: 'linear-gradient(90deg,rgba(17,17,18,1),rgba(51,51,51,1))',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}
const inputStyle = {
  width: 300,
  height: '2.6rem',
  padding: '0 0.5rem',
  border: '1px solid #ccc',
  borderRadius: '0.2rem',
  outline: 'none'
}
const buttonStyle = {
  height: '2.5rem',
  margin: '0 0 0 0.5rem',
  width: '8.75rem',
  background: '#f6bb07',
  borderRadius: '1.25rem',
  lineHeight: '2.5rem',
  border: 'none',
  fontSize: '1.2rem',
  fontWeight: 600,
  outline: 'none',
  cursor: 'pointer',
  color: '#333333'
}
const links = [{
  name: '壹立科技',
  link: 'http://www.yiliit.com/'
}, {
  name: '壹脉AI智能名片',
  link: 'http://www.yimaiai.com/'
}, {
  name: '飞翔下载',
  link: 'https://www.52z.com/'
}, {
  name: '好特下载',
  link: 'http://www.haote.com/'
}, {
  name: '多多软件',
  link: 'http://www.ddooo.com/'
}, {
  name: '比克尔',
  link: 'http://www.bkill.com/'
}, {
  name: '红软下载',
  link: 'http://www.rsdown.cn'
}, {
  name: '创e下载',
  link: 'http://www.7edown.com/'
}, {
  name: '偶要下载',
  link: 'http://www.ouyaoxiazai.com/'
}, {
  name: '绿色软件',
  link: 'http://www.downcc.com/'
}, {
  name: '系统之家',
  link: 'http://www.xitongzhijia.net/'
}, {
  name: '下载之家',
  link: 'https://www.xiazaizhijia.com/'
}]

const RECORD_NO = {
  cn: "蜀ICP备19002747号-5",
  com: " 苏ICP备2024102900号-1"
}

class Footer extends React.PureComponent {
  state = {
    phone: '',
    isClose: true
  };

  render() {
    let record = window.location.href.indexOf(".cn") > -1 ? "cn" : "com"
    console.log(RECORD_NO[record])
    return (
      <div id='footer'>
        <div style={{ background: '#333333', marginTop: 100 }}>
          <div className="containers">
            <Row style={{ margin: '0 auto' }}>
              <Col span={11} className="d-flex justify-content-start" style={{ marginTop: '79px' }}>
                <div className="text-left px-3">
                  <span className="text-white d-block" style={{ fontSize: '14px', lineHeight: '31px' }}>咨询热线：028-65731131</span>
                  <div style={{ marginTop: '30px' }}>
                    <Icon type="phone" style={{ color: '#fff' }} />
                    <span className="text-white ml-2">17721872191</span>
                    <span className="text-white ml-2">18081916514</span>
                  </div>
                  <div style={{ marginTop: '19px' }}>
                    <Icon type="mail" style={{ color: '#fff' }} />
                    <span className="text-white ml-3">yiliit@163.com</span>
                  </div>
                  <div style={{ marginTop: '19px' }}>
                    <Icon type="home" style={{ color: '#fff' }} />
                    <span className="text-white ml-2">盐城经济技术开发区新都东路82号8幢B1楼507室</span>
                  </div>
                  {/* <div>
                    <a style={{ display: 'inline-block', marginTop: 35 }} href="tencent://message/?uin=1115238556&amp;Site=有事Q我&amp;Menu=yes">
                      <button
                        style={{
                          height: "24px",
                          background: "#FFFFFF",
                          borderRadius: "12px",
                          fontSize: '12px',
                          fontWeight: 400,
                          color: '#383838'
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <img
                          src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/social-qq.png"
                          style={{ marginRight: "8px", width: 20, height: 20 }}
                          alt=''
                        />
                        <span>在线咨询</span>
                      </button>
                    </a>
                  </div> */}
                </div>
              </Col>
              <Col span={13} style={{ marginTop: '79px' }}>
                {/* <div className="text-center d-inline-block float-left" style={{ width: '16%' }}>
                  <span className="text-white text-center d-block" style={{ fontSize: '14px', lineHeight: '31px', marginBottom: '24px' }}>官方公众号</span>
                  < img alt="img" src={require('../asset/footer/ma.png')} width="72" height="72" />
                  < img alt="img" src={require('../asset/foooter_code1.png')} width="72" height="72" style={{ marginTop: 20 }} />
                  < img alt="img" src={require('../asset/foooter_code.jpg')} width="72" height="72" style={{ marginTop: 20 }} />
                </div>
                <div className="d-inline-block float-left" style={{ width: '16%' }} >
                  <span className="text-white text-center d-block" style={{ fontSize: '14px', lineHeight: '31px', marginBottom: '24px' }}>关注我们</span>
                  <div className="text-center footer-hover">
                    < img src={require('../asset/footer/wei.png')} alt="" width="30px" />
                    < img alt="img" src={require('../asset/footer/ma3.png')} className="footer-hover-img" />
                  </div>
                  <div className="text-center footer-hover mt-3">
                    < img src={require('../asset/footer/tou.png')} alt="" width="30px" />
                    < img alt="img" src={require('../asset/footer/ma2.png')} className="footer-hover-img" />
                  </div>
                  <div className="text-center footer-hover mt-3">
                    < img src={require('../asset/footer/dou.png')} alt="" width="30px" />
                    < img alt="img" src={require('../asset/footer/ma4.png')} className="footer-hover-img" />
                  </div>
                </div> */}
                <div className="d-inline-block float-left" style={{ width: '16%' }}>
                  <span className="text-white text-center d-block" style={{ fontSize: '14px', lineHeight: '31px', marginBottom: '24px' }}>关于我们</span>
                  {/* <a href="http://www.yiliit.com" className="text-center d-block" style={{ fontSize: '12px', color: '#AEAFB4' }}>关于壹立</a> */}
                  <span onClick={this.props.showDialog} className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4',cursor: 'pointer'}}>加入我们</span>
                </div>
                <div className="d-inline-block float-left" style={{ width: '22%' }}>
                  <span className="text-white text-center d-block" style={{ fontSize: '14px', lineHeight: '31px', marginBottom: '24px' }}>相关产品</span>
                  <a href="http://www.yimaiai.com" className="text-center d-block" style={{ fontSize: '12px', color: '#AEAFB4' }}>壹脉销客AI智能名片</a>
                  <a href="http://www.qolai.cn/take_out_food" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>来云台外卖系统</a>
                  <a href="/errands" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>来云台跑腿系统</a>
                  <a href="/" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>县城外卖跑腿系统</a>
                  <a href="/" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>乡镇外卖跑腿系统</a>
                  <a href="/" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>校园外卖跑腿系统</a>
                  <a href="/food" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>外卖配送系统</a>
                </div>
                <div className="d-inline-block float-left" style={{ width: '30%' }}>
                  <span className="text-white text-center d-block" style={{ fontSize: '14px', lineHeight: '31px', marginBottom: '24px' }}>售后服务</span>
                  <span className="text-center d-block" style={{ fontSize: '12px', color: '#AEAFB4' }}>服务时间: 周一至周日 9:00-22:00</span>
                  <span className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>公司专线: 028-65731131</span>
                  <span className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>售后专线: 18280230721</span>
                  <span className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>友链合作微信: 17345740721</span>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingBottom: '79px', marginTop: 20 }}>
              <div className="px-3" style={{ color: '#fff' }}>
                <span style={{ marginRight: 10 }}>友情链接:</span>
                {
                  links.map((item, index) => (
                    <a key={index} href={item.link} target="blank" style={style}>{item.name}</a>
                  ))
                }
              </div>
            </Row>
          </div>
        </div>
        <Row style={{ marginBottom: this.state.isClose ? 60 : 0 }} >
          <Col span={24} className="d-flex justify-content-center" style={{ background: 'rgba(51,51,51,0.95)' }}>
            <span style={{ fontSize: '12px', lineHeight: '41px', color: '#AEAFB4' }}>
              © 2019-2024 qolai.com 盐城中联数科技术有限公司 版权所有&nbsp;&nbsp;电信经营许可证：<a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%E5%B7%9DB2-20190570&code=63bm2&type=xuke" target="blank">川B2-20190570</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="blank">{RECORD_NO[record]}</a>
            </span>
          </Col>
        </Row>
        {
          this.state.isClose && (
            <div style={footer}>
              <p className="m-0" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#FFFFFF' }}>如需要演示及案例地址，请提交信息，我们会尽快与您联系</p>
              <div style={{ marginLeft: 50 }}>
                <input style={inputStyle} value={this.state.phone} onChange={e => {if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11);this.setState({ phone: e.currentTarget.value })}}/>
                <button style={buttonStyle} onClick={()=> {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.com',
                    page: 'footer',
                    button: 'submit'
                  }, 0);
                  this.props.submit(this.state.phone); 
                  this.setState({phone:''})
                }}>提交</button>
              </div>
              <div style={{ position: 'absolute', right: 22 }}>
                <Icon type="close" style={{ fontSize: 30 }} onClick={() => this.setState({ isClose: false })} />
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default Footer;