import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/information_delivery.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import Title from '../../components/Title';
import TitleLeft from '../../components/TitleLeft';


// 板块二数据
const blate2List = [
    {
        title: '自定义分类场景',
        icon: 'blate2List1'
    },
    {
        title: '信息分类发布',
        icon: 'blate2List2'
    },
    {
        title: '用户交流互动',
        icon: 'blate2List3'
    },
    {
        title: '支持信息举报',
        icon: 'blate2List4'
    },
    {
        title: '平台审核处理',
        icon: 'blate2List5'
    },
    {
        title: '支持付费发布',
        icon: 'blate2List6'
    }
]
const blate3List = [
    {
        label: '求职招聘',
        image: 'blate3Image1'
    },
    {
        label: '闲置出售',
        image: 'blate3Image2'
    },
    {
        label: '房屋租售',
        image: 'blate3Image3'
    },
    {
        label: '同城交友',
        image: 'blate3Image4'
    },
    {
        label: '本地资讯',
        image: 'blate3Image5'
    },
    {
        label: '表白墙',
        image: 'blate3Image6'
    }
]

const blate4List = [
    {
        title: '信息发布',
        data: ['免费/付费发布', '根据分类发布', '支持文字+图片', '可设置匿名']
    },
    {
        title: '审核管理',
        data: ['支持审核发布', '用户信息举报', '后台审核处理', '信息上下架']
    },
    {
        title: '趣味互动',
        data: ['评论交流', '转发好友', '点赞收藏', '在线联系']
    },
    {
        title: '热门话题',
        data: ['实时资讯', '重磅消息', '社会热点', '新闻热榜']
    }
]

let pageTimer = '' //停留时间
class InformationDelivery extends React.Component {
    state = {
        phone: '',
        baneerIndex: 0,
        blate3Index: 0,
        blate3Image: 'blate3Image1'
    };

    componentWillMount() {
        // 获取进入页面的时间
        if (pageTimer === '') {
            pageTimer = parseInt(new Date().getTime() / 1000);
            console.log(pageTimer, 'pageTimer')
        }
        this.props.onVisitClick({
            type: 1,
            domain: 'www.qolai.com',
            page: 'copy'
        }, 0)
    }

    componentWillUnmount() {
        // 离开时间减去进入时间
        const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
        console.log(tempTimer, 'tempTimer')
        // 清空时间戳
        pageTimer = '';
        if (tempTimer > 0) {
            this.props.onVisitClick({
                type: 2,
                domain: 'www.qolai.com',
                page: 'copy',
                time: tempTimer
            }, 0)
        }
    }

    onClickBlate3 = (index, image) => {
        this.setState({
            blate3Index: Number(index),
            blate3Image: image
        })
    }
    render() {
        return (
            <div className="w-100">
                <Helmet>
                    <title>来云台-信息发布系统|本地信息资讯平台|信息发布小程序</title>
                    <meta name="Description" content="来云台信息发布系统为本地/校园创业者打造平台生活交流互动平台，可汇集包括兼职招聘、二手交易、表白交友在内的多项信息服务，打造本地综合信息资讯平台；依托微信、抖音强大的流量体系和社交属性打造丰富的本地信息资讯等互动平台，赋能平台生活。" />
                    <meta name="Keywords" content="信息发布系统,本地信息资讯,信息发布小程序" />
                </Helmet>
                <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
                    <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
                        <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
                            <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>依托微信与抖音强大的流量体系和社交推广属性</div>
                            <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>打造丰富的<span style={{ color: '#F6BB07' }}>本地信息资讯</span>等互动平台，赋能平台生活</span>
                            <div className='Carousel-button d-flex animated fadeInRight'>
                                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({ phone: e.target.value }) }} placeholder='请输入您的手机号' />
                                <div onClick={() => { 
                                    this.props.onVisitClick({
                                        type: 3,
                                        domain: 'www.qolai.com',
                                        page: 'copy',
                                        button: 'onTrial'
                                      },0);
                                    this.props.submit(this.state.phone)
                                 }} className='Carousel-btn'>立即试用</div>
                            </div>
                        </div>
                    </div>
                </Carousel>
                {/**来云台信息发布系统  板块2**/}
                <div style={{ background: '#FFFFFF', margin: '100px 0' }}>
                    <div className={styles.blate2}>
                        <img src={require('../../asset/information_delivery/blate2Image1.png')} style={{ width: '630px', height: '448px' }} alt=''></img>
                        <div style={{ flex: 1, marginLeft: '40px' }}>
                            <TitleLeft title="来云台信息发布系统"
                                text="INFO RELEASE SYSTEM"
                                width={500}
                                height={66}
                                marginBottom={30} />
                            <div style={{ fontSize: '16px', color: '#707070', marginBottom: '40px' }}>为本地/校园创业者打造平台生活交流互动平台，可汇集包括兼职招聘、二手交易、表白交友在内的多项信息服务，打造本地综合信息资讯平台。</div>
                            <div className='d-flex align-content-center flex-wrap' style={{ width: '419px' }}>
                                {
                                    blate2List.map((item, index) => {
                                        return (
                                            <div key={index} className={styles.blate2Item}>
                                                <img src={require(`../../asset/information_delivery/${item.icon}.png`)} style={{ width: '60px', height: '60px' }} alt=''></img>
                                                <span>{item.title}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/**服务场景 板块3**/}
                <div style={{ padding: '100px 0', background: '#F7F9FC' }}>
                    <Title title="服务场景"
                        text="SERVICE SCENARIO"
                        width={710}
                        height={66} />
                    <div className={styles.blate3}>
                        <div>
                            {blate3List.map((row, index) => {
                                return (
                                    <div key={index} onMouseOver={() => { this.onClickBlate3(index, row.image) }} className={this.state.blate3Index === index ? `${styles.blate3Left} ${styles.blate3LeftActive} ` : `${styles.blate3Left}`}>
                                        {row.label}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={styles.blate3Right}>
                            <img alt="" src={require(`../../asset/information_delivery/${this.state.blate3Image}.png`)} style={{ width: '884px', height: '564px' }} />
                        </div>
                    </div>
                </div>
                {/**功能内容  板块4**/}
                <div style={{ margin: '100px 0', background: '#FFFFFF' }}>
                    <Title title="功能内容"
                        text="FUNCTION CONTENT"
                        width={710}
                        height={66} />
                    <div className={styles.blate4}>
                        {blate4List.map((row, index) => {
                            return (
                                <div className={styles.blate4Item} key={index}>
                                    <div className={`d-flex align-items-center justify-content-center ${styles.blate4ItemTop} ${index === 0 ? styles.blate4ItemTop1 : index === 1 ? styles.blate4ItemTop2 : index === 2 ? styles.blate4ItemTop3 : styles.blate4ItemTop4}`}>{row.title}</div>
                                    <div className={styles.blate4Bottom}>
                                        {row.data.map((item, indexs) => {
                                            return (
                                                <div key={indexs}>{item}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default InformationDelivery;