import React from 'react';
import ReactPlayer from 'react-player'

class manuell701 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>线下到店团购</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell701/manuell701.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell701/manuell701-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              团购是当下非常火爆的消费模式，线上选购优惠套餐、线下去消费，由平台为门店导流的方式现在非常热门，既通过优惠提升平台的订单量，又为门店带去了客流，并且这种消费模式虽然优惠，但是套餐内服务固定，若顾客还需要其他菜品或服务，到店后也需要按照店内价格支付消费。<br/>
              在来云台系统内，也支持这样的功能设置，团购模块的位置、页面由平台运营者在后台统一设置，然后给商家开启团购权限，商家即可设置团购套餐信息。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell701/manuell701-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell701/manuell701-3.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell701/manuell701-4.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell701/manuell701-5.jpg'/>
        </div>
    )
  }
}

export default manuell701;