import React from "react";
import Manuell001 from './components/manuell001'
import Manuell002 from './components/manuell002'
import Manuell003 from './components/manuell003'
import Manuell004 from './components/manuell004'
import Manuell005 from './components/manuell005'
import Manuell101 from './components/manuell101'
import Manuell102 from './components/manuell102'
import Manuell103 from './components/manuell103'
import Manuell104 from './components/manuell104'
import Manuell201 from './components/manuell201'
import Manuell202 from './components/manuell202'
import Manuell203 from './components/manuell203'
import Manuell204 from './components/manuell204'
import Manuell301 from './components/manuell301'
import Manuell302 from './components/manuell302'
import Manuell303 from './components/manuell303'
import Manuell304 from './components/manuell304'
import Manuell401 from './components/manuell401'
import Manuell402 from './components/manuell402'
import Manuell403 from './components/manuell403'
import Manuell404 from './components/manuell404'
import Manuell501 from './components/manuell501'
import Manuell502 from './components/manuell502'
import Manuell503 from './components/manuell503'
import Manuell504 from './components/manuell504'
import Manuell505 from './components/manuell505'
import Manuell506 from './components/manuell506'
import Manuell507 from './components/manuell507'
import Manuell508 from './components/manuell508'
import Manuell509 from './components/manuell509'
import Manuell510 from './components/manuell510'
import Manuell601 from './components/manuell601'
import Manuell602 from './components/manuell602'
import Manuell603 from './components/manuell603'
import Manuell604 from './components/manuell604'
import Manuell605 from './components/manuell605'
import Manuell606 from './components/manuell606'
import Manuell607 from './components/manuell607'
import Manuell608 from './components/manuell608'
import Manuell609 from './components/manuell609'
import Manuell701 from './components/manuell701'
import Manuell702 from './components/manuell702'


export const data = [
    {
        title: '系统相关内容',
        key: '0',
        children: [
            {
                title: '来云台外卖跑腿系统说明',
                key: '001',
                content: <Manuell001/>
            },
            {
                title: '智能调度',
                key: '002',
                content: <Manuell002/>
            },
            {
                title: '用户管理',
                key: '003',
                content: <Manuell003/>
            },
            {
                title: '配送范围与营业时间',
                key: '004',
                content: <Manuell004/>
            },
            {
                title: '资金管理',
                key: '005',
                content: <Manuell005/>
            }
        ]
    },
    {
        title: '骑手管理',
        key: '1',
        children: [
            {
                title: '骑手入驻',
                key: '101',
                content: <Manuell101/>
            },
            {
                title: '骑手佣金',
                key: '102',
                content: <Manuell102/>
            },
            {
                title: '骑手等级',
                key: '103',
                content: <Manuell103/>
            },
            {
                title: '骑手管理',
                key: '104',
                content: <Manuell104/>
            }
        ]
    },
    {
        title: '商家管理',
        key: '2',
        children: [
            {
                title: '商家入驻',
                key: '201',
                content: <Manuell201/>
            },
            {
                title: '商家发单配送费',
                key: '202',
                content: <Manuell202/>
            },
            {
                title: '商家抽成',
                key: '203',
                content: <Manuell203/>
            },
            {
                title: '商家管理',
                key: '204',
                content: <Manuell204/>
            }
        ]
    },
    {
        title: '跑腿设置',
        key: '3',
        children: [
            {
                title: '跑腿页面设置',
                key: '301',
                content: <Manuell301/>
            },
            {
                title: '跑腿场景设置',
                key: '302',
                content: <Manuell302/>
            },
            {
                title: '跑腿帮买店铺',
                key: '303',
                content: <Manuell303/>
            },
            {
                title: '跑腿抢单池',
                key: '304',
                content: <Manuell304/>
            }
        ]
    },
    {
        title: '外卖设置',
        key: '4',
        children: [
            {
                title: '丰富页面配置',
                key: '401',
                content: <Manuell401/>
            },
            {
                title: '二级页面配置',
                key: '402',
                content: <Manuell402/>
            },
            {
                title: '打烊预约下单',
                key: '403',
                content: <Manuell403/>
            },
            {
                title: '外卖抢单池',
                key: '404',
                content: <Manuell404/>
            }
        ]
    },
    {
        title: '配送功能设置',
        key: '5',
        children: [
            {
                title: '中转配送',
                key: '501',
                content: <Manuell501/>
            },
            {
                title: '预约配送地址',
                key: '502',
                content: <Manuell502/>
            },
            {
                title: '外卖商家自配送',
                key: '503',
                content: <Manuell503/>
            },
            {
                title: '外卖用户到店自取',
                key: '504',
                content: <Manuell504/>
            },
            {
                title: '外卖物流配送',
                key: '505',
                content: <Manuell505/>
            },
            {
                title: '第三方美/饿订单',
                key: '506',
                content: <Manuell506/>
            },
            {
                title: '第三方接口订单',
                key: '507',
                content: <Manuell507/>
            },
            {
                title: '第三方平台配送',
                key: '508',
                content: <Manuell508/>
            },
            {
                title: '扫码录单',
                key: '509',
                content: <Manuell509/>
            },
            {
                title: '扫码接单',
                key: '510',
                content: <Manuell510/>
            }
        ]
    },
    {
        title: '营销功能设置',
        key: '6',
        children: [
            {
                title: '跑腿优惠券',
                key: '601',
                content: <Manuell601/>
            },
            {
                title: '外卖优惠券',
                key: '602',
                content: <Manuell602/>
            },
            {
                title: '外卖营销活动',
                key: '603',
                content: <Manuell603/>
            },
            {
                title: '充值赠送',
                key: '604',
                content: <Manuell604/>
            },
            {
                title: '邀请有礼',
                key: '605',
                content: <Manuell605/>
            },
            {
                title: '营销广告',
                key: '606',
                content: <Manuell606/>
            },
            {
                title: '会员积分',
                key: '607',
                content: <Manuell607/>
            },
            {
                title: '分享有礼',
                key: '608',
                content: <Manuell608/>
            },
            {
                title: '营销表单',
                key: '609',
                content: <Manuell609/>
            }
        ]
    },
    {
        title: '增值功能',
        key: '7',
        children: [
            {
                title: '线下到店团购',
                key: '701',
                content: <Manuell701/>
            },
            {
                title: '同城信息',
                key: '702',
                content: <Manuell702/>
            }
        ]
    }
]