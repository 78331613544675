import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/platform_operation.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import TitleRed from '../../components/TitleRed';
import Title from '../../components/Title';

// 板块二数据

let pageTimer = '' //停留时间

class PlatformOperation extends React.Component {
    state = {
        phone: '',
        baneerIndex: 0,
    }
    componentWillMount() {
        // 获取进入页面的时间
        if (pageTimer === '') {
            pageTimer = parseInt(new Date().getTime() / 1000);
            console.log(pageTimer, 'pageTimer')
        }
        this.props.onVisitClick({
            type: 1,
            domain: 'www.qolai.com',
            page: 'platform'
        }, 0)
    }

    componentWillUnmount() {
        // 离开时间减去进入时间
        const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
        console.log(tempTimer, 'tempTimer')
        // 清空时间戳
        pageTimer = '';
        if (tempTimer > 0) {
            this.props.onVisitClick({
                type: 2,
                domain: 'www.qolai.com',
                page: 'platform',
                time: tempTimer
            }, 0)
        }
    }
    render() {
        return (
            <div className="w-100">
                <Helmet>
                    <title>来云台-抖音外卖小程序|QQ跑腿小程序|QQ外卖小程序|抖音跑腿小程序</title>
                    <meta name="Description" content="来云台外卖跑腿配送一套支持多平台运营的系统包含微信/抖音/头条/西瓜/皮皮虾小程序多终端合一，信息互联，掘金腾讯系与字节系巨量用户流量池；突破单平台发展模式，打造微信+字节10亿流量多平台多终端合一，抢占时代红利。" />
                    <meta name="Keywords" content="抖音跑腿小程序,抖音外卖小程序,QQ外卖跑腿小程序" />
                </Helmet>
                <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
                    <div className={`d-flex align-items-center justify-content-center ${styles.plate1Banner}`}>
                        <div className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
                            <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}><span style={{ color: '#F6BB07' }}>微信/抖音/头条/西瓜/皮皮虾</span>小程序多终端合一，信息互联</div>
                            <div className="banner-text-span" style={{ textAlign: 'center', fontSize: '36px', color: '#FFFFFF', fontWeight: "bold", marginBottom: '150px' }}>掘金<span style={{ color: '#F6BB07' }}>腾讯系与字节系</span>巨量用户流量池，抢占时代红利</div>
                            <div className='d-flex justify-content-center'>
                                <div className='Carousel-button d-flex animated fadeInRight'>
                                    <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({ phone: e.target.value }) }} placeholder='请输入您的手机号' />
                                    <div onClick={() => { 
                                        this.props.onVisitClick({
                                            type: 3,
                                            domain: 'www.qolai.com',
                                            page: 'platform',
                                            button: 'onTrial'
                                          },0);
                                        this.props.submit(this.state.phone)
                                     }} className='Carousel-btn'>立即试用</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>
                {/**产品介绍  板块2**/}
                {/* <div style={{ margin: '100px 0', background: '#FFFFFF' }}>
                    <Title title="产品介绍"
                        text="PRODUCT INTRODUCTION"
                        width={710}
                        height={66} />
                    <img src={require('../../asset/platform_operation/plate2Image.png')} style={{ width: '1280px', height: '529px', display: 'block', margin: '0 auto' }} alt=''></img>
                </div> */}
                {/**多种合作模式  板块3**/}
                <div style={{ padding: '100px 0', background: '#F7F9FC' }}>
                    <Title title="多种合作模式"
                        text="multiple cooperation modes"
                        width={710}
                        height={66}
                        marginBottom={50} />
                    <div
                        style={{
                            fontSize: '20px',
                            color: '#707070',
                            textAlign: 'center'
                        }}
                    >突破单平台发展模式，打造微信---字节数10亿流量多平台多终端合一，抢占时代红利</div>
                    <img src={require('../../asset/platform_operation/plate3Image.png')} style={{ width: '1117px', height: '554px', display: 'block', margin: '50px auto 0' }} alt=''></img>
                </div>
                {/**多种合作模式  板块4**/}
                <div style={{ margin: '100px 0', background: '#FFFFFF' }}>
                    <TitleRed
                        titleFront="入局本地生活"
                        spanColor="抖音市场"
                        titleRear="，这块蛋糕有多大？"
                        text="TIKTOK MARKET"
                        width={710}
                        height={66}
                        marginBottom={50} />
                    <div
                        style={{
                            fontSize: '24px',
                            fontWeight: '700',
                            color: '#121213',
                            textAlign: 'center'
                        }}
                    >搭乘时代顺风车，紧握短视频流量风口，瓜分8亿日活用户</div>
                    <img src={require('../../asset/platform_operation/plate4Image.png')} style={{ width: '1440px', height: '720px', display: 'block', margin: '60px auto 0' }} alt=''></img>
                </div>
                {/**一个产品，多个平台  板块5**/}
                <div style={{ padding: '100px 0', backgroundImage: `url(${require('../../asset/platform_operation/system-oto-modulesB-bg.png')})`, backgroundSize: '100% 100%', backgroundRepeat: ' no-repeat' }}>
                    <Title title="一个产品，多个平台"
                        text="MULTI PLATFORM"
                        titleColor='#FFFFFF'
                        textColor='#FFFFFF'
                        width={710}
                        height={66} />
                    <div style={{ height: '508px' }} className='d-flex justify-content-center'>
                        <div className="d-flex" style={{ width: "1440px" }}>
                            <div style={{ width: "432px", marginRight: '239px', paddingTop: '148px' }}>
                                <div style={{ fontSize: '36px', fontWeight: 'bold', color: '#fff', marginBottom: '40px' }}>
                                    一个字节小程序
                                </div>
                                <div style={{ fontSize: '24px', color: '#fff' }}>
                                    即可实现
                                </div>
                                <div style={{ fontSize: '24px', color: '#fff' }}>
                                    旗下多平台同时公共全面提升品牌曝光率
                                </div>
                            </div>
                            <div style={{ flex: 1, position: "relative" }}>
                                <div className={styles.plate5Item} style={{ position: 'absolute', top: "140px", left: '0', background: 'rgba(253,58,60,0.3)' }}>
                                    <img alt='' src={require(`../../asset/platform_operation/multi-platform-moduleD1.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                                    头条极速版
                                </div>
                                <div className={styles.plate5Item} style={{ position: 'absolute', top: "0px", left: '224px', background: 'rgba(43,207,136,0.3)' }}>
                                    <img alt='' src={require(`../../asset/platform_operation/multi-platform-moduleD2.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                                    抖音极速版
                                </div>
                                <div className={styles.plate5Item} style={{ position: 'absolute', top: "252px", left: '224px', background: 'rgba(24,135,230,0.3)' }}>
                                    <img alt='' src={require(`../../asset/platform_operation/multi-platform-moduleD3.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                                    火山小视频
                                </div>
                                <div className={styles.plate5Item} style={{ position: 'absolute', top: "124px", left: '429px', background: 'rgba(253,99,78,0.3)' }}>
                                    <img alt='' src={require(`../../asset/platform_operation/multi-platform-moduleD4.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                                    今日头条
                                </div>
                                <div className={styles.plate5Item} style={{ position: 'absolute', top: "302px", left: '539px', background: 'rgba(255,121,30,0.3)' }}>
                                    <img alt='' src={require(`../../asset/platform_operation/multi-platform-moduleD5.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                                    抖音火山版
                                </div>
                                <div className={styles.plate5Item} style={{ position: 'absolute', top: "0", left: '620px', background: 'rgba(74,126,230,0.3)' }}>
                                    <img alt='' src={require(`../../asset/platform_operation/multi-platform-moduleD6.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                                    抖音
                                </div>
                                <div className={styles.plate5Item} style={{ position: 'absolute', top: "178px", left: '753px', background: 'rgba(255,98,143,0.3)' }}>
                                    <img alt='' src={require(`../../asset/platform_operation/multi-platform-moduleD7.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                                    皮皮虾
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/**来云台，一套支持多平台运营的系统  板块6**/}
                <div style={{ margin: '100px 0', background: '#FFFFFF' }}>
                    <Title title="来云台，一套支持多平台运营的系统"
                        text="MULTI PLATFORM OPERATION"
                        width={710}
                        height={66} />
                    <img src={require('../../asset/platform_operation/plate6Image.png')} style={{ width: '100%', height: '253px' }} alt=''></img>
                </div>
            </div>
        );
    }
}

export default PlatformOperation;