import React from 'react';
import { Button, Carousel, message } from 'antd';
import '../../App.css';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import dayjs from 'dayjs';
import {
  newsList,
} from '../../apis/Information';
import {
  Link
} from 'react-router-dom';

// components
import Title from '../../components/Title';

const blate2List = [
  {
    title: '同城生活系统',
    leftData: [
      {
        label: '本地跑腿配送系统',
        icon: 'blate2icon1',
        image: 'blate2List1',
      },
      {
        label: '同城外卖系统',
        image: 'blate2List2',
        icon: 'blate2icon2'
      },
      {
        label: '同城生活服务系统',
        image: 'blate2List3',
        icon: 'blate2icon3'
      }
    ],
    data: [
      {
        path: '/errands',
        cont: '适用于打造本地跑腿配送平台，支持多元化服务场景自定义设置，可对接美团、饿了么、抖音等第三方自配送订单,搭建万能跑腿配送服务平台，全面赋能本地配送创业者。',
        iconArr: [
          {
            icon: 'blate2ListIcon1',
            text: '跑腿帮办'
          },
          {
            icon: 'blate2ListIcon2',
            text: '餐饮配送'
          },
          {
            icon: 'blate2ListIcon3',
            text: '家政上门'
          },
          {
            icon: 'blate2ListIcon4',
            text: '货运搬家'
          },
          {
            icon: 'blate2ListIcon5',
            text: '代驾服务'
          }
        ]
      },
      {
        path: '/food',
        cont: '对标美团平台，融合同城生活多行业，覆盖本地全业态商圈，为创业者打造自主品牌的本地O2O生活平台。',
        iconArr: [
          {
            icon: 'blate2ListIcon6',
            text: '餐饮美食'
          },
          {
            icon: 'blate2ListIcon7',
            text: '奶茶甜品'
          },
          {
            icon: 'blate2ListIcon8',
            text: '安全药品'
          },
          {
            icon: 'blate2ListIcon9',
            text: '蔬菜生鲜'
          },
          {
            icon: 'blate2ListIcon10',
            text: '当季水果'
          }
        ]
      },
      {
        path: '/service',
        cont: '包含跑腿代办、餐饮、零售、生鲜多行业支持，从订餐下单，在线结算，到完成配送，提供本地生活流程提供专业解决方案。',
        iconArr: [
          {
            icon: 'blate2ListIcon11',
            text: '全业态服务'
          },
          {
            icon: 'blate2ListIcon12',
            text: '掌上生活圈'
          },
          {
            icon: 'blate2ListIcon13',
            text: '一站式搭建'
          },
          {
            icon: 'blate2ListIcon14',
            text: '即时配送服务'
          },
          {
            icon: 'blate2ListIcon15',
            text: '物流货运配送'
          }
        ]
      }
    ]
  },
  {
    title: '校园平台系统',
    leftData: [
      {
        label: '校园跑腿调度系统',
        icon: 'blate2icon4',
        image: 'blate2List4'
      },
      {
        label: '校园外卖平台系统',
        image: 'blate2List5',
        icon: 'blate2icon5'
      }
    ],
    data: [
      {
        path: '/distribution',
        cont: '针对多元的校园跑腿场景，与校园巨大的餐饮配送需求，通过对接美团饿了么订单接口，结合智能调度、校园中转、集中配送等模式提高校园效率。',
        iconArr: [
          {
            icon: 'blate2ListIcon16',
            text: '多元服务场景'
          },
          {
            icon: 'blate2ListIcon17',
            text: '自由设置计价'
          },
          {
            icon: 'blate2ListIcon18',
            text: '智能调度'
          },
          {
            icon: 'blate2ListIcon19',
            text: '中转配送'
          },
          {
            icon: 'blate2ListIcon20',
            text: '自选配送池'
          }
        ]
      },
      {
        path: '/campus',
        cont: '打造学校移动掌上生活圈，支持学生创业者整合校内外餐饮美食商家，给学生提供线上点餐、外卖配送全套生活服务，打造独立品牌的智慧校园平台。',
        iconArr: [
          {
            icon: 'blate2ListIcon21',
            text: '食堂外送'
          },
          {
            icon: 'blate2ListIcon22',
            text: '校外美食'
          },
          {
            icon: 'blate2ListIcon23',
            text: '送餐到寝'
          },
          {
            icon: 'blate2ListIcon24',
            text: '丰富营销'
          },
          {
            icon: 'blate2ListIcon25',
            text: '好友代付'
          }
        ]
      }
    ]
  },
  {
    title: '多平台运营系统',
    leftData: [
      {
        label: '多站点加盟平台',
        image: 'blate2List6',
        icon: 'blate2icon6'
      },
      {
        label: '多渠道运营平台',
        image: 'blate2List7',
        icon: 'blate2icon7'
      },
      {
        label: '信息发布系统',
        image: 'blate2List8',
        icon: 'blate2icon8'
      },
      {
        label: '本地团购系统',
        image: 'blate2List9',
        icon: 'blate2icon9'
      }
    ],
    data: [
      {
        path: '/sjoining',
        cont: '支持同一平台小程序下，分区域、分站点独立运营，站点自主设置页面、收款账户，独立管理商家、骑手，用户自由选择站点下单。',
        iconArr: [
          {
            icon: 'blate2ListIcon26',
            text: '区域加盟'
          },
          {
            icon: 'blate2ListIcon27',
            text: '站点切换'
          },
          {
            icon: 'blate2ListIcon28',
            text: '独立计价'
          },
          {
            icon: 'blate2ListIcon29',
            text: '独立运营'
          },
          {
            icon: 'blate2ListIcon30',
            text: '分站结算'
          }
        ]
      },
      {
        path: '/platform',
        cont: '微信、抖音、头条、皮皮虾小程序多端合一，内容设置与信息数据互动，精准触达多平台消费用户，覆盖全域网络。',
        iconArr: [
          {
            icon: 'blate2ListIcon31',
            text: '多流量入口'
          },
          {
            icon: 'blate2ListIcon32',
            text: '页面统一'
          },
          {
            icon: 'blate2ListIcon33',
            text: '信息互通'
          },
          {
            icon: 'blate2ListIcon34',
            text: '更高曝光'
          },
          {
            icon: 'blate2ListIcon35',
            text: '覆盖更多用户'
          }
        ]
      },
      {
        path: 'copy',
        cont: '打造分类信息交流平台，包括表白墙、二手交易、兼职招聘、房屋出租等信息共享服务，打造移动生活圈里的信息资讯平台。',
        iconArr: [
          {
            icon: 'blate2ListIcon36',
            text: '自定义信息分类'
          },
          {
            icon: 'blate2ListIcon37',
            text: '发布审核'
          },
          {
            icon: 'blate2ListIcon38',
            text: '付费发布'
          },
          {
            icon: 'blate2ListIcon39',
            text: '评论互动'
          },
          {
            icon: 'blate2ListIcon40',
            text: '信息举报'
          }
        ]
      },
      {
        path: '/group',
        cont: '借鉴抖音团购、大众点评运营模式，充分整合本地资源，实现线上购买线下核销，通过优惠折扣和网络红利，为实体门店消费引流。',
        iconArr: [
          {
            icon: 'blate2ListIcon41',
            text: '美食套餐'
          },
          {
            icon: 'blate2ListIcon42',
            text: '丽人美发'
          },
          {
            icon: 'blate2ListIcon43',
            text: '按摩理疗'
          },
          {
            icon: 'blate2ListIcon44',
            text: '景点门票'
          },
          {
            icon: 'blate2ListIcon45',
            text: '酒店民宿'
          }
        ]
      }
    ]
  }
]
// 板块二数据
const plate2 = {
  tabs: [
    {
      title: '调度配送',
    },
    {
      title: '服务场景',
    },
    {
      title: '校园配送',
    },
    {
      title: '增值功能',
    },
    {
      title: '支付方式',
    }
  ],
  plate2List: [
    {
      tab: '调度配送',
      title: '智能调度配送',
      cont: '多种调度方式由运营者自由选择，智能调度根据条件筛选优先派单；开放API接口，支持对接美团/饿了么以及更多第三方外卖平台，对接自配送商家订单。',
      image: 'blate3List1',
      iconArr: [
        {
          icon: 'blate3ListIcon1',
          text: '智能调度'
        },
        {
          icon: 'blate3ListIcon2',
          text: '回程单'
        },
        {
          icon: 'blate3ListIcon3',
          text: '后台指派'
        },
        {
          icon: 'blate3ListIcon4',
          text: '开放API'
        },
        {
          icon: 'blate3ListIcon5',
          text: '对接美团/饿了么'
        }
      ]
    },
    {
      tab: '服务场景',
      title: '多元服务场景',
      cont: '支持自定义设置跑腿服务场景与外卖服务类型，除跑腿、外卖，还支持运营家政上门、货运搬家、打车代驾、本地团购、信息发布等多种服务类型，服务多元，收益更多。',
      image: 'blate3List2',
      iconArr: [
        {
          icon: 'blate3ListIcon6',
          text: '自定义服务设置'
        },
        {
          icon: 'blate3ListIcon7',
          text: '页面自由配置'
        },
        {
          icon: 'blate3ListIcon8',
          text: '多元页面模板'
        },
        {
          icon: 'blate3ListIcon9',
          text: '自由设置计价'
        },
        {
          icon: 'blate3ListIcon10',
          text: '外部小程序跳转'
        }
      ]
    },
    {
      tab: '校园配送',
      title: '校园配送优势',
      cont: '针对校园订单集中，且出入相对受限的配送场所，可通过设立中转配送、分段配送、用户自选配送池等方式，降低配送成本，提升服务质量，保障快速送货上门。',
      image: 'blate3List3',
      iconArr: [
        {
          icon: 'blate3ListIcon11',
          text: '中转配送'
        },
        {
          icon: 'blate3ListIcon12',
          text: '集中配送'
        },
        {
          icon: 'blate3ListIcon13',
          text: '分段配送'
        },
        {
          icon: 'blate3ListIcon14',
          text: '男女骑手分池'
        },
        {
          icon: 'blate3ListIcon15',
          text: '用户自选配送池'
        }
      ]
    },
    {
      tab: '增值功能',
      title: '平台增值功能',
      cont: '除常规的跑腿服务和餐饮外送，平台支持更多元的增值服务提供，包括本地团购、信息发布、物流配送等等，包括覆盖字节旗下的全线流量平台。',
      image: 'blate3List4',
      iconArr: [
        {
          icon: 'blate3ListIcon16',
          text: '本地团购'
        },
        {
          icon: 'blate3ListIcon17',
          text: '信息发布'
        },
        {
          icon: 'blate3ListIcon18',
          text: '物流配送'
        },
        {
          icon: 'blate3ListIcon19',
          text: '抖音小程序'
        },
        {
          icon: 'blate3ListIcon20',
          text: '头条小程序'
        }
      ]
    },
    {
      tab: '支付方式',
      title: '多样化支付方式',
      cont: '多种支付方式并行，提供给客户多样化的选择，全方位满足客户需求，打造人性化、科学化、智能化的本地生活服务平台。',
      image: 'blate3List5',
      iconArr: [
        {
          icon: 'blate3ListIcon21',
          text: '微信支付'
        },
        {
          icon: 'blate3ListIcon22',
          text: '余额支付'
        },
        {
          icon: 'blate3ListIcon23',
          text: '银行卡支付'
        },
        {
          icon: 'blate3ListIcon24',
          text: '货到付款'
        },
        {
          icon: 'blate3ListIcon25',
          text: '好友代付'
        }
      ]
    }
  ]
}

const blate4List = {
  id: 1,
  leftData: ['用户端', '骑手端', '商家端', '调度后台', '多站点管理后台'],
  data: [
    {
      image: 'blate4List1',
      iconArr: [
        {
          icon: 'blate4ListIcon1',
          text: '海量营销'
        },
        {
          icon: 'blate4ListIcon2',
          text: '外部跳转'
        },
        {
          icon: 'blate4ListIcon3',
          text: '附近骑手'
        },
        {
          icon: 'blate4ListIcon4',
          text: '预计接单'
        },
        {
          icon: 'blate4ListIcon5',
          text: '站点切换'
        },
        {
          icon: 'blate4ListIcon6',
          text: '位置监控'
        },
        {
          icon: 'blate4ListIcon7',
          text: '开屏广告'
        },
        {
          icon: 'blate4ListIcon8',
          text: '意见反馈'
        }
      ],
      blate4CodeArr: [
        {
          codeImg: 'blate4Code2',
          title: '在线体验跑腿端',
          icon: 'blate4Code5'
        },
        {
          codeImg: 'blate4Code3',
          title: '在线体验外卖端',
          icon: 'blate4Code5'
        },
        {
          title: '在线体验',
          codeImg: 'blate4Code1',
          icon: 'blate4Code4'
        }
      ]
    },
    {
      image: 'blate4List2',
      iconArr: [
        {
          icon: 'blate4ListIcon9',
          text: '实名认证'
        },
        {
          icon: 'blate4ListIcon10',
          text: '保证金'
        },
        {
          icon: 'blate4ListIcon11',
          text: '奖惩制度'
        },
        {
          icon: 'blate4ListIcon12',
          text: '排班制度'
        },
        {
          icon: 'blate4ListIcon13',
          text: '骑手保险'
        },
        {
          icon: 'blate4ListIcon14',
          text: '收取件码'
        },
        {
          icon: 'blate4ListIcon15',
          text: '扫码录单'
        },
        {
          icon: 'blate4ListIcon16',
          text: '拍照回传'
        }
      ],
      blate4CodeArr: [
        {
          codeImg: 'blate4Code6',
          title: '下载[Ios APP]',
          icon: 'blate4Code5'
        },
        {
          codeImg: 'blate4Code7',
          title: '下载[Android APP]',
          icon: 'blate4Code5'
        }
      ]
    },
    {
      image: 'blate4List3',
      iconArr: [
        {
          icon: 'blate4ListIcon17',
          text: '手动录单'
        },
        {
          icon: 'blate4ListIcon18',
          text: '拍照发单'
        },
        {
          icon: 'blate4ListIcon19',
          text: '充值赠送'
        },
        {
          icon: 'blate4ListIcon20',
          text: '经营数据'
        },
        {
          icon: 'blate4ListIcon21',
          text: '打印配置'
        },
        {
          icon: 'blate4ListIcon22',
          text: '扫码核销'
        },
        {
          icon: 'blate4ListIcon23',
          text: '商品管理'
        },
        {
          icon: 'blate4ListIcon24',
          text: '活动中心'
        }
      ],
      blate4CodeArr: [
        {
          codeImg: 'blate4Code8',
          title: '下载[Ios APP]',
          icon: 'blate4Code5'
        },
        {
          codeImg: 'blate4Code9',
          title: '下载[Android APP]',
          icon: 'blate4Code5'
        }
      ]
    },
    {
      image: 'blate4List4',
      iconArr: [
        {
          icon: 'blate4ListIcon25',
          text: '页面配置'
        },
        {
          icon: 'blate4ListIcon26',
          text: '场景配置'
        },
        {
          icon: 'blate4ListIcon27',
          text: '自由计价'
        },
        {
          icon: 'blate4ListIcon28',
          text: '可视化操作'
        },
        {
          icon: 'blate4ListIcon29',
          text: '订单指派'
        },
        {
          icon: 'blate4ListIcon30',
          text: '智能调度'
        },
        {
          icon: 'blate4ListIcon31',
          text: '财务统计'
        },
        {
          icon: 'blate4ListIcon32',
          text: '提现打款'
        }
      ],
      path: 'https://www.qolai.com/admin'
    },
    {
      image: 'blate4List5',
      iconArr: [
        {
          icon: 'blate4ListIcon33',
          text: '团队管理'
        },
        {
          icon: 'blate4ListIcon34',
          text: '编辑授权'
        },
        {
          icon: 'blate4ListIcon35',
          text: '财务统计'
        },
        {
          icon: 'blate4ListIcon36',
          text: '资金结算'
        },
        {
          icon: 'blate4ListIcon37',
          text: '提现配置'
        },
        {
          icon: 'blate4ListIcon38',
          text: '提现打款'
        },
        {
          icon: 'blate4ListIcon39',
          text: '用户统计'
        },
        {
          icon: 'blate4ListIcon40',
          text: '数据导出'
        }
      ],
      path:'http://www.qolai.com/site'
    }
  ]
}

const blate5List = [
  {
    title: '一站式平台搭建',
    cont: '全程技术与售后平台搭建，绑定自己的收款账户，3-5个工作日成果交付，直接上手运营。'
  },
  {
    title: '保姆式售后指导',
    cont: '专人售后一对一服务，提供详细的操作文档与视频资料，5*12h在线指导，随时解疑答惑。'
  },
  {
    title: '运营指导方案',
    cont: '提供专业的市场运营指导方案、免费提供商家、骑手合作协议模板与用户使用协议模板。'
  },
  {
    title: '专业技术支持',
    cont: '免费提供系统维护，以及后续平台系统的功能更新迭代，7*24h运维、高级防护全方位守护。'
  }
]
const blate6List = [
  {
    title: '国家高新技术企业',
    cont: '国家级技术资质认定，权威、专业、信任。'
  },
  {
    title: '独立知识产权',
    cont: '来云台为盐城中联数科技术有限公司自主研发的软件产品，拥有独立知识产权。'
  },
  {
    title: '自主商标产权',
    cont: '来云台为盐城中联数科技术有限公司独立商标产权，拥有独立品牌优势。'
  },
  {
    title: '4000+合作案例',
    cont: '服务客户遍布国内外多个区域，覆盖校园、城市、乡镇。'
  }
]
const blate7List = [
  {
    title: '校园案例',
    data: [
      {
        icon: 'blate7List3',
        name: '校园跑腿',
        cont: '广西某学院男生寝室合伙创业，搭建的送货到寝的跑腿配送平台，服务多元，包括游戏陪玩、电脑清灰、代扔垃圾等等，日订单600-800。'
      },
      {
        icon: 'blate7List4',
        name: '校园外卖',
        cont: '某外国语大学校内学生创业者自建的外卖点餐平台，整合了校内外餐饮美食商家，开通表白墙信息发布，搭建校内信息交流平台。'
      }
    ]
  },
  {
    title: '乡镇案例',
    data: [
      {
        icon: 'blate7List5',
        name: '县城跑腿',
        cont: '四川达州某创业者在县城搭建的本地配送平台，承接当地的跑腿服务，对接当地外卖平台的自配送商家，日订单高达2000单左右。'
      },
      {
        icon: 'blate7List6',
        name: '乡镇外卖',
        cont: '云南某乡镇外卖平台，当地还未被美团饿了么覆盖，搭建了本地的外卖平台，专注于乡镇外卖服务，目前已开通3个乡镇运营。'
      }
    ]
  },
  {
    title: '国外案例',
    data: [
      {
        icon: 'blate7List7',
        name: '亚洲案例',
        cont: '日本华侨客户用来云台外卖系统在大阪运营外卖平台，覆盖涵盖外卖美食、甜品蛋糕、当地特产等等，日均订单量在1000左右。'
      },
      {
        icon: 'blate7List8',
        name: '欧洲案例',
        cont: '白俄罗斯华侨通过来云台系统搭建的集外卖、跑腿、团购、信息发布于一体的本地生活服务平台，集吃喝玩乐购，打造便捷移动掌上生活圈'
      }
    ]
  }
]
let pageTimer = '' //停留时间

class Home extends React.Component {
  state = {
    phone: '',
    baneerIndex: 0,
    plateIndex: 0,
    blateIndex: 0,
    blate8List: [],
    blate8TitleArr: [],
    blate2Image: 'blate2List1',
    blate3Index: 0,
    blate4Index: 0
  };
  componentWillMount() {
    this.fetchData()
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'home'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.com',
        page: 'home',
        time: tempTimer
      },0)
    }
  }

  slickGoToIndex = index => {
    this.slider.slick.slickGoTo(index)
    this.setState({
      plateIndex: Number(index),
      blateIndex: 0,
      blate2Image: `blate2List${index === 0 ? 1 : index === 1 ? 4 : 6}`
    })
  }
  onClickBlate = (index, image) => {
    this.setState({
      blateIndex: Number(index),
      blate2Image: image
    })
  }
  goToAdmin = () => {
    window.location.href = 'http://www.qolai.com/admin#/user/register'
  }
  goToIndex = index => {
    this.sliders.slick.slickGoTo(index)
    this.setState({
      blate4Index: Number(index)
    })
  }
  fetchData = async () => {
    const result = await newsList(3, 1, '');
    if (result.code === 200) {
      let arr = result.data.list ? result.data.list.slice(0, 3) : []
      let arr1 = result.data.list ? result.data.list.slice(3, 9) : []
      this.setState({
        blate8List: arr,
        blate8TitleArr: arr1
      })
    }
  }
  slick2Index = index => {
    this.slider2.slick.slickGoTo(index)
    this.setState({
      blate3Index: Number(index)
    })
  }
  render() {
    return (
      <div className="w-100">
        <Helmet>
          <title>来云台-配送系统|校园乡镇外卖|外卖跑腿平台软件|同城o2o专业服务商</title>
          <meta name="Description" content="来云台旗下有同城生活服务系统、校园外卖跑腿系统、多平台运营系统专为跑腿团队、外卖商家、跑腿公司、本地创业者、同城外卖配送服务商、校园外卖跑腿团队、快递公司提供全套的跑腿技术支持和外卖o2o平台，解决传统跑腿配送系统运营难点：服务成本高、跑腿订单少、跑腿系统效率低、配送系统不规范、定位不精准、团队管理无体系、客户资源管理难、订单推送不及时、管理调度效率低，一站式解决运营难题，来云台外卖、配送系统打通“即时配送”最后一公里。" />
          <meta name="Keywords" content="外卖配送系统,外卖系统,校园外卖跑腿配送,跑腿系统"/>
        </Helmet>
        <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }} >
          <div className="home-banner1 d-flex justify-content-center">
            <div className="banner-title" style={{ paddingTop: '37px', textAlign: 'center' }}>
              <div className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })} >
                <div className={classnames('bannerA-top-title')} style={{ marginBottom: '20px' }}>
                  <span className="bannerA-top-title-left">
                  </span>
                  <div className="bannerA-top-title-middle">
                    “来云台”一站式解决本地生活服务
                  </div>
                  <span className="bannerA-top-title-right">
                  </span>
                </div>
                <span className="banner-text-spanA d-block" style={{ fontSize: '18px', color: '#FFFFFF', marginBottom: '30px' }}>跑腿帮办 • 餐饮外卖 • 本地团购 • 信息发布</span>
                <div className="d-flex justify-content-center">
                  <img src={require('../../asset/img_code_2.png')} style={{ width: '180px', height: '180px' }} alt=''></img>
                </div>
                <Button
                  ghost
                  style={{ fontWeight: '700' }}
                  className={classnames('banner-btnb', 'animated', 'm-0', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>扫码体验产品</Button>
                <div>
                  <span className="d-block" style={{ marginTop: '25px', marginBottom: '10px', letterSpacing: '3px', fontSize: '20px', lineHeight: '36px', fontWeight: '500', color: '#FFFFFF' }}>全套解决方案 - 微信/抖音小程序 + 微信H5 + APP（Android+IOS）+ PC后台 </span>
                  <span className="d-block bannerA-service">免费维护 | 包更新升级 | 品牌定制 | 私有化部署 | 应对高并发高负载 | 7*24h在线售后</span>
                  <span className="d-block" style={{ marginTop: '10px', fontSize: '14px', color: '#ffffff', opacity: '0.6' }}>— 来云台，科技助力生活 —</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-banner2 d-flex align-items-center">
            <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 1, 'd-none': this.state.baneerIndex !== 1 })}>
              <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>类似美团，覆盖本地生活全业态范围</div>
              <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>保姆式扶持<span style={{ color: '#F6BB07' }}>同城/校园/乡镇</span>创业者平台搭建</span>
              <div className={classnames('bannar-icon-box')}>
                <div className={classnames('bannar-icon-list')}>
                  <img src={require('../../asset/ic_banner1.png')} style={{ width: '40px', height: '40px' }} alt=''></img>
                  <span>一站式搭建</span>
                </div>
                <div className={classnames('bannar-icon-list')}>
                  <img src={require('../../asset/ic_banner2.png')} style={{ width: '40px', height: '40px' }} alt=''></img>
                  <span>专人售后</span>
                </div>
                <div className={classnames('bannar-icon-list')}>
                  <img src={require('../../asset/ic_banner3.png')} style={{ width: '40px', height: '40px' }} alt=''></img>
                  <span>运营指导</span>
                </div>
                <div className={classnames('bannar-icon-list')}>
                  <img src={require('../../asset/ic_banner4.png')} style={{ width: '40px', height: '40px' }} alt=''></img>
                  <span>协议模板</span>
                </div>
              </div>
              <div className='Carousel-button d-flex animated fadeInRight'>
                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({ phone: e.target.value }) }} placeholder='请输入您的手机号' />
                <div onClick={async () => { 
                    this.props.onVisitClick({
                      type: 3,
                      domain: 'www.qolai.com',
                      page: 'home',
                      button: 'onTrial'
                    },0);
                    this.props.submit(this.state.phone)
                  }} 
                className='Carousel-btn'>立即试用</div>
              </div>
            </div>
          </div>
          <div className="home-banner3 d-flex align-items-center">
            <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 2, 'd-none': this.state.baneerIndex !== 2 })}>
              <h1 style={{ fontSize: "36px", color: "#ffffff", marginBottom: '20px', fontWeight: "bold" }}><span style={{ color: '#F6BB07' }}>来云台</span> · 外卖跑腿O2O系统</h1>
              <span className="banner-text-span" style={{ fontSize: "20px", color: "#ffffff", lineHeight: "40px", marginBottom: '40px' }}>打造类似美团/饿了么的独立外卖平台<br />国内外4000+自主品牌平台案例</span>
              <div className={classnames('bannar-icon-box')} style={{ marginTop: '40px' }}>
                <div className={classnames('bannar-icon-list')} style={{ background: 'rgba(64, 86, 255, 0.4)' }}>
                  <img src={require('../../asset/ic_banner5.png')} style={{ width: '40px', height: '40px' }} alt=''></img>
                  <span>校园创业</span>
                </div>
                <div className={classnames('bannar-icon-list')} style={{ background: 'rgba(64, 86, 255, 0.4)' }}>
                  <img src={require('../../asset/ic_banner6.png')} style={{ width: '40px', height: '40px' }} alt=''></img>
                  <span>乡镇案例</span>
                </div>
                <div className={classnames('bannar-icon-list')} style={{ background: 'rgba(64, 86, 255, 0.4)' }}>
                  <img src={require('../../asset/ic_banner7.png')} style={{ width: '40px', height: '40px' }} alt=''></img>
                  <span>本地平台</span>
                </div>
                <div className={classnames('bannar-icon-list')} style={{ background: 'rgba(64, 86, 255, 0.4)' }}>
                  <img src={require('../../asset/ic_banner8.png')} style={{ width: '40px', height: '40px' }} alt=''></img>
                  <span>国外案例</span>
                </div>
              </div>
              <div className='QR-Code-Box'>
                <Button style={{ width: '250px', fontWeight: "bold" }} ghost className={classnames('banner-btnb', 'animated', 'm-0', { fadeInRight: this.state.baneerIndex === 2, 'd-none': this.state.baneerIndex !== 2 })}>案例获取 More+</Button>
                <img className='QR-Code' src={require('../../asset/home/QRCode.png')} alt=''></img>
              </div>
            </div>
          </div>
        </Carousel>
        {/**多种跑腿服务场景，打造“懒人经济”下的绝佳体验  板块2**/}
        <div style={{ marginTop: '80px', background: '#ffffff' }}>
          <Title title="来云台外卖跑腿系统 — 专注本地生活平台打造"
            text="LIVING PLATFORM"
            width={710}
            height={66} />
          <div className="d-flex justify-content-center  align-items-center" style={{ marginTop: '0px', marginBottom: '24px' }}>
            <div onMouseMove={() => { this.slickGoToIndex(0) }} className={classnames('home-plate2-btn1', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.plateIndex === 0 })}>
              同城生活系统
            </div>
            <span className="home-plate2-span"></span>
            <div onMouseMove={() => { this.slickGoToIndex(1) }} className={classnames('home-plate2-btn1', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.plateIndex === 1 })}>
              校园平台系统
            </div>
            <span className="home-plate2-span"></span>
            <div onMouseMove={() => { this.slickGoToIndex(2) }} className={classnames('home-plate2-btn1', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.plateIndex === 2 })}>
              多平台运营系统
            </div>
          </div>
          <Carousel dots={false} ref={slider => (this.slider = slider)} className='blate2-box'>
            {
              blate2List.map((item, index) => {
                return (
                  <div key={index} className='blate2'>
                    <div className='blate2-left-box'>
                      {item.leftData.map((row, blate2Index) => {
                        return (
                          <div key={blate2Index} onMouseMove={() => { this.onClickBlate(blate2Index, row.image) }} className={this.state.blateIndex === blate2Index ? 'blate2-left-active' : 'blate2-left'}>
                            <img alt="img" src={require(`../../asset/home/${row.icon}.png`)} style={{ width: '68px', height: '68px' }} />
                            <span>{row.label}</span>
                          </div>
                        )
                      })

                      }
                    </div>
                    <div className='blate2-right'>
                      <div>
                        <div className='blate2-right-top'>
                          <div className='blate2-right-top-left'>
                            <span className='blate2-right-text'>{item.data[this.state.blateIndex]?.cont}</span>
                            <div className='d-flex'>
                              {item.data[this.state.blateIndex]?.iconArr.map((row, indexs) => {
                                return (
                                  <div key={indexs} className='icon-item'>
                                    <img alt="" src={require(`../../asset/home/${row.icon}.png`)} style={{ width: '60px', height: '60px' }} />
                                    <span className='icon-text'>{row.text}</span>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          <img alt="" src={require(`../../asset/home/${this.state.blate2Image}.png`)} style={{ width: '340px', height: '340px' }} />
                        </div>
                        <div className="d-flex justify-content-center  align-items-center" style={{ paddingTop: '30px' }}>
                          <div onClick={() => { 
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.com',
                                page: 'home',
                                button: 'experience'
                              },0);
                              this.props.showDialog()
                            }} className='btn-all' style={{ marginRight: '18px' }}>点击免费体验</div>
                          <div onClick={()=>{
                            this.props.onVisitClick({
                              type: 3,
                              domain: 'www.qolai.com',
                              page: 'home',
                              button: 'more'
                            }, () => window.location.href=`${item.data[this.state.blateIndex]?.path}`);
                          }} className='btn-all btn-all-black'>查看更多 <span style={{ fontWeight: '700' }}>More+</span></div>
                        </div>
                      </div>

                    </div>
                  </div>
                )
              })}
          </Carousel>
        </div>
        {/**来云台-有效提升合作客户的市场竞争力  板块3**/}
        <div className='blate3'>
          <div style={{ width: '100%', height: '360px', background: 'linear-gradient(90deg,rgba(255,204,12,1),rgba(246,187,7,1))', paddingTop: "72px" }}>
            <Title
              title="来云台-有效提升合作客户的市场竞争力"
              titleColor="#121213"
              text="MARKET CPNESS"
              width={582}
              height={68}
            />
          </div>
          <div className='blate3-content'>
            <div className="d-flex align-items-center" style={{ borderBottom: '2px solid #E2E2E2', justifyContent: 'space-between', cursor: 'pointer' }}>
              {plate2.tabs.map((row, index) => {
                return (
                  <div key={index} className='blate3Tabs' onMouseMove={() => { this.slick2Index(index) }} >
                    <span style={{ margin: '20px 13px' }} className={this.state.blate3Index === index ? `blate3TabsTitle blate3TabsTitleActive` : `blate3TabsTitle`}>{row.title}</span>
                    <span className={this.state.blate3Index === index ? `blate3TabsLineActive blate3TabsLine` : `blate3TabsLine`}></span>
                  </div>
                )
              })}
            </div>
            <Carousel dots={false} ref={slider => (this.slider2 = slider)} style={{ paddingTop: '50px' }}>
              {
                plate2.plate2List.map((row, index) => {
                  return (
                    <div key={index} className='d-flex'>
                      <div className='blate3-tabs-left'>
                        <img alt="" src={require(`../../asset/home/${row.image}.png`)} style={{ width: '632px', height: '440px' }} />
                      </div>
                      <div className='blate3-tabs-right'>
                        <div className='blate3-tabs-right-title'>{row.title}</div>
                        <div className='blate3-tabs-right-content'>{row.cont}</div>
                        <div className='d-flex align-items-center' style={{ marginLeft: '-30px' }}>
                          {
                            row.iconArr.map((icon, indexs) => {
                              return (
                                <div key={indexs} className='d-flex align-items-center justify-content-center' style={{ flexDirection: 'column', width: '114px' }}>
                                  <img alt='' src={require(`../../asset/home/${icon.icon}.png`)} style={{ width: '60px', height: '60px' }} />
                                  <span style={{ fontSize: '15px', marginTop: '12px', color: '#333333', fontWeight: '700' }}>{icon.text}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className='btn-all' onClick={() => { 
                          this.props.onVisitClick({
                            type: 3,
                            domain: 'www.qolai.com',
                            page: 'home',
                            button: 'experience'
                          },0);
                          this.props.showDialog() 
                        }}>点击免费体验</div>
                      </div>
                    </div>
                  )
                })}
            </Carousel>
          </div>
        </div>
        {/* 多端互联·无缝衔接高效交互 */}
        <div style={{ paddingBottom: '100px' }}>
          <Title
            title="多端互联·无缝衔接高效交互"
            titleColor='#121213'
            text='MARKET CPNESS'
            width={591}
            height={68}
          />
          <div className='blate4'>
            <div span={8} className="d-flex" style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
              {blate4List.leftData.map((item, index) => {
                return (
                  <div key={index} onMouseMove={() => { this.goToIndex(index) }} className={classnames('home-plate2-btn', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.blate4Index === index })}>
                    {item}
                  </div>
                )
              })}
            </div>
            <div className='blate4-carousel'>
              <Carousel dots={false} ref={slider => (this.sliders = slider)}>
                {
                  blate4List.data.map((item, index) => {
                    return (
                      <>
                        {index === 0 || index === 1 || index === 2 ?
                          <div key={index} className='d-flex justify-content-between'>
                          <div>
                            <div className='d-flex' style={{ flexWrap: 'wrap', padding: '80px 0' }}>
                              {item.iconArr.map((row, indexs) => {
                                return (
                                  <div key={indexs} className='blate4-icon-item1'>
                                    <img alt="" src={require(`../../asset/home/${row.icon}.png`)} style={{ width: '80px', height: '80px' }} />
                                    <span className='blate4-icon-text'>{row.text}</span>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          <img alt="" src={require(`../../asset/home/${item.image}.png`)} className='d-block' style={{ width: '552px', height: '556px', margin: '30px 173px 30px 100px' }} />
                        </div>
                          :
                          <div key={index} className='d-flex justify-content-between'>
                            <div>
                              <div className='d-flex' style={{ flexWrap: 'wrap', padding: '80px 0' }}>
                                {item.iconArr.map((row, indexs) => {
                                  return (
                                    <div key={indexs} className='blate4-icon-item1'>
                                      <img alt="" src={require(`../../asset/home/${row.icon}.png`)} style={{ width: '80px', height: '80px' }} />
                                      <span className='blate4-icon-text'>{row.text}</span>
                                    </div>
                                  )
                                })}
                              </div>
                              <div style={{ marginTop: '85px' }} onClick={()=>{
                                this.props.onVisitClick({
                                  type: 3,
                                  domain: 'www.qolai.com',
                                  page: 'home',
                                  button: 'experienceNow'
                                },() => window.open(item.path));
                              }}><div className='btn-all'>立即体验 <span style={{ fontWeight: '700' }}>More+</span></div></div>
                            </div>
                            <img alt="" src={require(`../../asset/home/${item.image}.png`)} className='d-block' style={{ width: '552px', height: '556px', margin: '30px 173px 30px 100px' }} />
                          </div>
                        }</>
                    )
                  })}
              </Carousel>
            </div>
          </div>
        </div>
        {/* 来云台-保姆式创业扶持 */}
        <div style={{ paddingBottom: '100px' }}>
          <Title
            title="来云台-保姆式创业扶持"
            titleColor='#121213'
            text='NANNY STYLE'
            width={591}
            height={68}
          />
          <div className='blate5'>
            {
              blate5List.map((row, index) => {
                return (
                  <div key={index} className='blate5-item' style={{ marginLeft: 100 * index, zIndex: blate5List.length - index }}>
                    <div className='d-flex align-items-center'>
                      <img alt='' src={require(`../../asset/blate5List${index + 1}.png`)} style={{ width: '30px', height: '30px' }} />
                      <span style={{ fontSize: '24px', marginLeft: '10px', color: '#121213', fontWeight: '700' }}>{row.title}</span>
                    </div>
                    <div className='blate5-cont'>{row.cont}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* 盐城中联数科技术有限公司-企业优势 */}
        <div style={{ paddingBottom: '100px', background: '#F7F9FC', paddingTop: '100px' }}>
          <Title
            title="盐城中联数科技术有限公司-企业优势"
            titleColor='#121213'
            text='ENTERPRISE ADVANTAGES'
            width={591}
            height={68}
          />
          <div className='blate6'>
            {
              blate6List.map((row, index) => {
                return (
                  <div key={index} className='blate6-item'>
                    <div className='blate6-item-top'>
                      <div className='blate6-item-img-box'>
                        <div className='blate6-item-img'><img alt='' src={require(`../../asset/ic_saafe_${index + 1}.png`)} style={{ width: '50px', height: '50px' }} /></div>
                      </div>
                      <span>{row.title}</span>
                    </div>
                    <div className='blate6-cont'>{row.cont}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* 客户案例 */}
        <div style={{ paddingBottom: '100px', background: `url(${require('../../asset/blate7bg.png')})`, backgroundSize: '100% 100%', paddingTop: '100px' }}>
          <Title
            title="客户案例"
            titleColor='#FFFFFF'
            textColor='#FFFFFF'
            text='CUSTOMER STORIES'
            width={591}
            height={68}
          />
          <div className='blate7'>
            {
              blate7List.map((row, index) => {
                return (
                  <div key={index} className='blate7-item'>
                    <div className='blate7-item-top'>
                      <img alt='' src={require(`../../asset/blate7List${index}.png`)} style={{ width: '100px', height: '100px' }} />
                      <span>{row.title}</span>
                    </div>
                    <div className='blate7-cont'>
                      {row.data.map((item, indexs) => {
                        return (
                          <div key={indexs} className='d-flex' style={{ marginTop: '22px' }}>
                            <img className='d-block' src={require(`../../asset/${item.icon}.png`)} alt='' style={{ width: '68px', height: '68px', borderRadius: '10px', marginRight: '14px' }}></img>
                            <div className='blate7-cont-right'>
                              <span className='d-block' style={{ color: '#333333', fontSize: '20px', marginBottom: '8px' }}>{item.name}</span>
                              <span className='d-block' style={{ color: '#707070', fontSize: '16px' }}>{item.cont}</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div
            onClick={async ()=>{
              await this.props.onVisitClick({
                type: 3,
                domain: 'www.qolai.com',
                page: 'home',
                button: 'learnMore'
              }, () => window.location.href='/case');
            }}
          ><div className='btn-all' style={{ margin: '40px auto 0' }}>了解更多 <span style={{ fontWeight: '700' }}>More+</span></div></div>
        </div>
        {/*合作伙伴 */}
        <div style={{ paddingTop: '100px' }}>
          <Title
            title="合作伙伴"
            titleColor='#121213'
            text='PARTNERS'
            width={591}
            height={68}
          />
          <div className="d-flex justify-content-center" >
            <div style={{ width: "1440px", display: "flex", justifyContent: "space-between" }}>
              <div className="module7_columns">
                <div id="module7_columns_ul">
                  <img alt='' src={require(`../../asset/homeIcon/cooperative-partnerA.png`)} mode="aspectFill" style={{ width: " 50%", height: "76px" }} />
                  <img alt='' src={require(`../../asset/homeIcon/cooperative-partnerA.png`)} mode="aspectFill" style={{ width: " 50%", height: "76px" }} />
                </div>
                <div id="module7_columns_ul2">
                  <img alt='' src={require(`../../asset/homeIcon/cooperative-partnerB.png`)} mode="aspectFill" style={{ width: " 50%", height: "76px" }} />
                  <img alt='' src={require(`../../asset/homeIcon/cooperative-partnerB.png`)} mode="aspectFill" style={{ width: " 50%", height: "76px" }} />
                </div>
                <div id="module7_columns_ul3">
                  <img alt='' src={require(`../../asset/homeIcon/cooperative-partnerC.png`)} mode="aspectFill" style={{ width: " 50%", height: "76px" }} />
                  <img alt='' src={require(`../../asset/homeIcon/cooperative-partnerC.png`)} mode="aspectFill" style={{ width: " 50%", height: "76px" }} />
                </div>
                <div className="maskA"></div>
                <div className="maskB"></div>
              </div>
            </div>
          </div>
        </div>
        {/*盐城中联数科技术有限公司，实力企业资质 */}
        {/* <div style={{ paddingBottom: '100px', background: '#F7F9FC', paddingTop: '100px' }}>
          <Title
            title="盐城中联数科技术有限公司，实力企业资质"
            titleColor='#121213'
            text='STRONG ENTERPRISE'
            width={591}
            height={68}
          />
          <div className="d-flex justify-content-center" >
            <div style={{ width: "100%", maxWidth: "1920px", display: "flex", justifyContent: "space-between" }}>
              <div className="module8_columns">
                <div id="module8_columns_ul">
                  <img alt='' src={require(`../../asset/homeIcon/certifications.png`)} mode="aspectFill" style={{ width: " 50%", height: "420px" }} />
                  <img alt='' src={require(`../../asset/homeIcon/certifications.png`)} mode="aspectFill" style={{ width: " 50%", height: "420px" }} />
                </div>
                <div className="maskA"></div>
                <div className="maskB"></div>
              </div>
            </div>
          </div>
        </div> */}
        {/*新闻动态 */}
        <div>
          <Title
            title="新闻动态"
            titleColor='#121213'
            text='NEWS'
            width={591}
            height={68}
          />
          <div className="blate8" >
            {
              this.state.blate8List.map((row, index) => {
                return (
                  <Link key={index} className="information-news-list blate8-item" to={`/information_detail?id=${row.news_id}`}>
                    <div className='blate8-item-top'>
                      <img alt='' src={row.news_photos} style={{ width: '100%', height: '261px' }} />
                      <div className='blate8-cont'>
                        <span className='blate8-cont-title'>{row.news_title}</span>
                        <span className='blate8-cont-time'>{dayjs(row.create_time).format('YYYY年M月D日')}</span>
                      </div>
                      <div className='d-flex align-items-center' style={{ justifyContent: 'space-between', padding: '0 40px 28px' }}>
                        <span style={{ fontSize: '12px', color: '#A2A0A0' }}>Details</span>
                        <span style={{ fontSize: '12px', color: '#35373D' }}>→</span>
                      </div>
                    </div>
                  </Link>
                )
              })
            }
          </div>
          <div className="blate8-title" >
            {this.state.blate8TitleArr.map((item, index) => {
              return (
                <Link key={index} className="information-news-list blate8-item" to={`/information_detail?id=${item.news_id}`}>
                  <div className='blate8-item-center'>{item.news_title}</div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;